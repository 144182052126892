import { createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import { APP_COLORS } from "./colors";

/**
 * Typography System Documentation
 *
 * Hierarchy:
 *
 * Headings:
 * - h1: Main page titles (40px)
 * - h2: Section titles (32px)
 * - h3: Subsection titles (28px)
 * - h4: Card titles (24px)
 * - h5: Small section titles (20px)
 * - h6: Small titles (18px)
 *
 * Body:
 * - body1: Main body text (16px)
 * - body2: Secondary body text (14px)
 *
 * Other:
 * - subtitle1: Emphasized text (18px)
 * - subtitle2: Secondary emphasized text (14px)
 * - caption: Helper text (12px)
 * - caption2: Smallest text (10px)
 * - largeLead: Large introductory text (20px)
 * - smallLead: Small introductory text (18px)
 *
 * Usage:
 *
 * 1. Using MUI Typography:
 * <Typography variant="h1">Page Title</Typography>
 *
 * 2. Using Component:
 * <H1>Page Title</H1>
 *
 * 3. With custom styles:
 * <Typography variant="body1" sx={{ color: "primary.main" }}>
 *   Custom colored text
 * </Typography>
 */

// Defining custom typography variants
declare module "@mui/material/styles" {
  interface TypographyVariants {
    largeLead: React.CSSProperties;
    smallLead: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    largeLead?: React.CSSProperties;
    smallLead?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

// Update Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    largeLead: true;
    smallLead: true;
    caption2: true;
  }
}

const typography: TypographyOptions = {
  fontFamily: "SF-Pro-Rounded, sans-serif",

  // Main headings
  h1: {
    fontSize: "2.5rem", // 40px
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: "-0.02em"
  },
  h2: {
    fontSize: "2rem", // 32px
    lineHeight: 1.25,
    fontWeight: 700,
    letterSpacing: "-0.01em"
  },
  h3: {
    fontSize: "1.75rem", // 28px
    lineHeight: 1.3,
    fontWeight: 600
  },
  h4: {
    fontSize: "1.5rem", // 24px
    lineHeight: 1.35,
    fontWeight: 600
  },
  h5: {
    fontSize: "1.25rem", // 20px
    lineHeight: 1.4,
    fontWeight: 600
  },
  h6: {
    fontSize: "1.125rem", // 18px
    lineHeight: 1.45,
    fontWeight: 600
  },

  // Body text
  body1: {
    fontSize: "1rem", // 16px
    lineHeight: 1.5,
    fontWeight: 400
  },
  body2: {
    fontSize: "0.875rem", // 14px
    lineHeight: 1.5,
    fontWeight: 400
  },

  // Other variants
  subtitle1: {
    fontSize: "1.125rem", // 18px
    lineHeight: 1.5,
    fontWeight: 500
  },
  subtitle2: {
    fontSize: "0.875rem", // 14px
    lineHeight: 1.5,
    fontWeight: 500
  },
  caption: {
    fontSize: "0.75rem", // 12px
    lineHeight: 1.5,
    fontWeight: 400
  },

  // Custom variants
  largeLead: {
    fontSize: "1.25rem", // 20px
    lineHeight: 1.6,
    fontWeight: 400
  },
  smallLead: {
    fontSize: "1.125rem", // 18px
    lineHeight: 1.6,
    fontWeight: 400
  },
  caption2: {
    fontSize: "0.625rem", // 10px
    lineHeight: 1.5,
    fontWeight: 400
  }
};

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    requestContained: true;
    requestOutlined: true;
    shareContained: true;
    shareOutlined: true;
  }
}

/**
 * Color System Documentation
 *
 * Usage:
 * 1. In MUI components: theme.palette.[category].[variant]
 * 2. In styled components: ${({ theme }) => theme.palette.[category].[variant]}
 * 3. In sx props: sx={{ color: '[category].[variant]' }}
 *
 * Categories:
 * - primary: Main brand colors (teal)
 * - secondary: Supporting colors
 * - neutral: Grays and whites
 * - feedback: Success, error, warning, info
 * - surface: Background and surface colors
 * - border: Border colors
 * - text: Text colors
 *
 * Each category includes these variants where applicable:
 * - main: Primary color
 * - light: Lighter version
 * - dark: Darker version
 * - hover: Hover state
 * - contrast: Contrast text color
 */

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    surface: Palette["primary"];
    border: Palette["primary"];
    states: {
      hover: {
        primary: string;
        secondary: string;
        neutral: string;
      };
      pressed: {
        primary: string;
        secondary: string;
        neutral: string;
      };
    };
    feedback: {
      success: string;
      error: string;
      warning: string;
      info: string;
      successLight: string;
      errorLight: string;
      warningLight: string;
      infoLight: string;
    };

    custom: Palette["primary"];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"] & { white?: string } & { red?: string };
    surface?: PaletteOptions["primary"];
    border?: PaletteOptions["primary"] & { ghost?: string } & {
      metrics?: string;
    } & { metricsHover?: string } & { white?: string } & { resource?: string };
    states?: {
      hover: {
        primary: string;
        secondary: string;
        neutral: string;
      };
      pressed: {
        primary: string;
        secondary: string;
        neutral: string;
      };
    };
    feedback?: {
      success: string;
      error: string;
      warning: string;
      info: string;
      successLight: string;
      errorLight: string;
      warningLight: string;
      infoLight: string;
    };

    custom?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    white: string;
    red: string;
    ghost: string;
    metrics: string;
    metricsHover: string;
    metricsBg: string;
    gray: string;
    mediumLightGray: string;
    request: string;
    requestHover: string;
    share: string;
    shareHover: string;
    resource: string;
  }
  interface TypeBackground {
    metricsBg: string;
    gray: string;
    mediumLightGray: string;
  }

  interface TypeText {
    mediumLightGray: string;
  }

  interface TypeAction {
    request?: string;
    requestHover?: string;
    share?: string;
    shareHover?: string;
  }
}

const color = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: APP_COLORS.primaryMain, // #209498
      light: APP_COLORS.primaryLight, // #6cccbd
      dark: "#006064",
      contrastText: "#ffffff"
    },
    secondary: {
      main: APP_COLORS.secondaryMain, // #a6afb7
      light: APP_COLORS.secondaryLight, // #d2d7db
      dark: "#8a919a",
      contrastText: "#000000"
    },
    states: {
      hover: {
        primary: "#1b8387",
        secondary: "#95a0aa",
        neutral: APP_COLORS.mediumGray
      },
      pressed: {
        primary: "#157175",
        secondary: "#8a919a",
        neutral: APP_COLORS.darkGray
      }
    },
    neutral: {
      main: APP_COLORS.darkGray, // #626262
      light: APP_COLORS.lightGray, // #DFE3E7
      dark: APP_COLORS.black, // #000000
      white: APP_COLORS.white, //#fff
      red: APP_COLORS.resourceBorder
    },
    surface: {
      main: APP_COLORS.white, // #fff
      light: APP_COLORS.backgroundLight, // #FBFBFB
      dark: APP_COLORS.extraLightGray // #f5f5f5
    },
    border: {
      main: APP_COLORS.formBorderColor, // #e5e7eb
      light: APP_COLORS.lightGray, // #DFE3E7
      dark: APP_COLORS.mediumGray, // #D4D4D4
      ghost: APP_COLORS.buttonGray, //#D8D8D8
      metrics: APP_COLORS.metricsBorderColor, //#E4E7EC,
      metricsHover: APP_COLORS.metricsAlpha, //#146981E6
      white: APP_COLORS.white, //#fff
      resource: APP_COLORS.resourceBorder //#F44336
    },
    feedback: {
      success: "#16a34a",
      error: APP_COLORS.red, // #d32f2f
      warning: "#f59e0b",
      info: APP_COLORS.infoMain, // #2196f3
      successLight: "#dcfce7",
      errorLight: "#fee2e2",
      warningLight: "#fef3c7",
      infoLight: "#e0f2fe"
    },
    action: {
      request: APP_COLORS.requestColor, //#FF6F61
      requestHover: APP_COLORS.requestColorHover, //#e66457
      share: APP_COLORS.shareColor, //#f5dd9d
      shareHover: APP_COLORS.shareColorHover //#ddc78d
    },
    text: {
      primary: APP_COLORS.black,
      secondary: APP_COLORS.subtitleColor, // #344054
      disabled: APP_COLORS.buttonGrayText, // #7F7F7F
      mediumLightGray: APP_COLORS.mediumLightGray //#C3C3C3
    },
    background: {
      default: APP_COLORS.backgroundDefault, // #fafafa
      paper: APP_COLORS.white, // #fff
      metricsBg: APP_COLORS.metricsBg, //#20949810
      gray: APP_COLORS.gray, //#F9F9F9
      mediumLightGray: APP_COLORS.mediumLightGray //#C3C3C3
    },
    custom: {
      main: APP_COLORS.white,
      light: APP_COLORS.white,
      dark: APP_COLORS.white,
      contrastText: APP_COLORS.black
    }
  },
  typography,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.2)"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    }
  }
});
export const theme = createTheme(color, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "requestContained" },
          style: {
            color: APP_COLORS.white,
            borderColor: APP_COLORS.requestColor,
            border: "1px solid transparent",
            backgroundColor: APP_COLORS.requestColor,
            "&:hover": {
              backgroundColor: APP_COLORS.requestColorHover,
              borderColor: APP_COLORS.requestColorHover
            }
          }
        },
        {
          props: { variant: "requestOutlined" },
          style: {
            borderColor: APP_COLORS.shareColor,
            color: APP_COLORS.subtitleColor,
            border: "1px solid",
            "&:hover": {
              color: APP_COLORS.white,
              backgroundColor: APP_COLORS.requestColorHover,
              borderColor: APP_COLORS.requestColorHover
            }
          }
        },
        {
          props: { variant: "shareContained" },
          style: {
            color: APP_COLORS.subtitleColor,
            borderColor: APP_COLORS.shareColor,
            border: "1px solid transparent",
            backgroundColor: APP_COLORS.shareColor,
            "&:hover": {
              backgroundColor: APP_COLORS.shareColorHover,
              borderColor: APP_COLORS.shareColorHover
            }
          }
        },
        {
          props: { variant: "shareOutlined" },
          style: {
            borderColor: APP_COLORS.shareColor,
            color: APP_COLORS.subtitleColor,
            border: "1px solid",
            "&:hover": {
              color: APP_COLORS.subtitleColor,
              backgroundColor: APP_COLORS.shareColorHover,
              borderColor: APP_COLORS.shareColorHover
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          // borderRadius: 8,
          "&:hover": {
            boxShadow: "none"
          },
          "&:active": {
            boxShadow: "none"
          },
          "&:focus": {
            boxShadow: "none"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: color.palette.secondary.light
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: color.palette.secondary.main
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: color.palette.primary.main
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px"
        },
        underline: {
          "&:before": {
            borderBottomColor: color.palette.secondary.light
          },
          "&:hover:before": {
            borderBottomColor: color.palette.secondary.main
          },
          "&.Mui-focused:before": {
            borderBottomColor: color.palette.primary.main
          }
        }
      }
    }
  }
});
