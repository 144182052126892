import React from "react";
import { useDispatch } from "react-redux";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { Input } from "@components/base/Input";
import {
  clearSearchResults,
  searchResourceName
} from "@features/InsitutionDatabase/resourceSlice";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Autocomplete, CircularProgress, Stack } from "@mui/material";
import { FormikProps } from "formik";
import { AppDispatch } from "src/services/store";
import { autoCompleteStyles } from "src/styles/main";

interface ManualEntryFieldsProps {
  isMobile: boolean;
  disabledFields: Record<string, boolean>;
  setDisabledFields: React.Dispatch<
    React.SetStateAction<{
      strainName: boolean;
      mutation_type_names: boolean;
      target_gene_names: boolean;
      species_name: boolean;
    }>
  >;
  initialValues: CreateResourceFormValues | FormikResourceValues;
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
  handleClearConnectedFields: () => void;
}

const ManualEntryFields: React.FC<ManualEntryFieldsProps> = ({
  formik,
  resourcePropsNames,
  loading,
  setResourcePropsValues,
  resourcePropsValues,
  setDisabledFields,
  disabledFields,
  handleClearConnectedFields,
  isMobile
}) => {
  const dispatch: AppDispatch = useDispatch();

  const searchStrainNameorCellLineName = async (strainName: string) => {
    dispatch(clearSearchResults());
    setDisabledFields((prev) => ({
      ...prev,
      mutation_type_names: false,
      target_gene_names: false
    }));
    dispatch(searchResourceName({ name: strainName }));
  };

  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id={"strainName"}
        resourceNames={resourcePropsNames?.name || []}
        label={"Strain Name"}
        loading={loading}
        value={formik.values.strainName}
        disabled={disabledFields.strainName}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });

          if (newValue != null) {
            const newValueString = newValue.toString();
            if (resourcePropsNames?.name.includes(newValueString)) {
              formik.setFieldValue("strainName", newValueString);
              searchStrainNameorCellLineName(newValueString);
            } else {
              formik.setFieldValue("strainName", newValue?.toString() || "");
            }
          } else {
            formik.setFieldValue("strainName", "");
            handleClearConnectedFields();
          }
        }}
        error={formik.touched.strainName && Boolean(formik.errors.strainName)}
        helperText={
          (formik.touched.strainName && formik.errors.strainName) || ""
        }
      />
      <Stack direction={isMobile ? "column" : "row"} spacing={3}>
        <ResourceAutocomplete
          id={"species_name"}
          fullWidth
          resourceNames={resourcePropsNames?.species_name || []}
          value={formik.values.species_name || ""}
          label={"Species Name"}
          disabled={disabledFields.species_name}
          loading={loading}
          requiredField
          optionsFilter={optionsFilter}
          onChange={(_, newValue) => {
            setResourcePropsValues({
              ...resourcePropsValues,
              species_name: newValue
            });
            formik.setFieldValue("species_name", newValue?.toString() || "");
          }}
          error={
            formik.touched.species_name && Boolean(formik.errors.species_name)
          }
          helperText={
            (formik.touched.species_name && formik.errors.species_name) || ""
          }
        />

        <Autocomplete
          id={"synonyms"}
          fullWidth
          multiple
          options={[]}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          ChipProps={{ color: "primary", variant: "outlined" }}
          filterOptions={optionsFilter}
          getOptionLabel={(option) => option}
          onChange={(_, newValue) => {
            setResourcePropsValues({
              ...resourcePropsValues,
              synonyms: newValue
            });
            formik.setFieldValue("synonyms", (newValue || "").toString());
          }}
          renderInput={(params) => (
            <Input
              {...params}
              label={"Synonyms"}
              error={formik.touched.synonyms && Boolean(formik.errors.synonyms)}
              helperText={
                (formik.touched.synonyms && formik.errors.synonyms) || ""
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} className={autoCompleteStyles}>
              <>Add &quot;{option}&quot;</>
            </li>
          )}
        />
      </Stack>
      <Stack direction={isMobile ? "column" : "row"} spacing={3}>
        <ResourceAutocomplete
          id={"target_gene_names"}
          fullWidth
          multiple
          resourceNames={resourcePropsNames?.target_gene_names || []}
          label={"Target Gene(s)"}
          loading={loading}
          value={formik.values?.target_gene_names || []}
          disabled={disabledFields.target_gene_names}
          optionsFilter={optionsFilter}
          onChange={(_, newValue) => {
            const updatedValue = Array.isArray(newValue)
              ? newValue
              : [newValue];
            setResourcePropsValues({
              ...resourcePropsValues,
              target_gene_names: updatedValue
            });
            formik.setFieldValue("target_gene_names", updatedValue);
          }}
          error={
            formik.touched.target_gene_names &&
            Boolean(formik.errors.target_gene_names)
          }
          helperText={
            Array.isArray(formik.errors.target_gene_names)
              ? formik.errors.target_gene_names.join(", ")
              : formik.errors.target_gene_names || ""
          }
        />
        <ResourceAutocomplete
          id={"mutation_type_names"}
          fullWidth
          multiple
          resourceNames={resourcePropsNames?.mutation_type_names || []}
          value={formik.values.mutation_type_names || []}
          label={"Mutation Type(s)"}
          loading={loading}
          disabled={disabledFields.mutation_type_names}
          optionsFilter={optionsFilter}
          onChange={(_, newValue) => {
            const updatedValue = Array.isArray(newValue)
              ? newValue
              : [newValue];
            setResourcePropsValues({
              ...resourcePropsValues,
              mutation_type_names: updatedValue
            });
            formik.setFieldValue("mutation_type_names", updatedValue);
          }}
          error={
            formik.touched.mutation_type_names &&
            Boolean(formik.errors.mutation_type_names)
          }
          helperText={
            Array.isArray(formik.errors.mutation_type_names)
              ? formik.errors.mutation_type_names.join(", ")
              : formik.errors.mutation_type_names || ""
          }
        />
      </Stack>
    </Stack>
  );
};

export default ManualEntryFields;
