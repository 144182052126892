import { H3, Subtitle1 } from "@components/base/Typography";
import { Button, ButtonProps, styled } from "@mui/material";

export const ShipporiTypographyMain = styled(H3)(() => ({
  fontFamily: '"Shippori-Mincho-B1", serif'
}));

export const ShipporiTypographySubtitle = styled(Subtitle1)(() => ({
  fontFamily: '"Shippori-Mincho-B1", serif'
}));

export const CustomizedInviteButton = styled(Button)<ButtonProps>(() => ({
  textDecoration: "none",
  "&:hover": {
    background: "none",
    textDecoration: "underline"
  }
}));
