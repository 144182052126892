import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentUser, getUser } from "@features/Auth/authSlice";
import { AppBar, Box, Toolbar } from "@mui/material";
import { AppDispatch } from "src/services/store";

import Logo from "./Logo";
import MobileMenu from "./MobileMenu";
import NavigationButtons from "./NavigationButtons";
import SearchBar from "./SearchBar";

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    custom: true;
  }
}
interface TSAppBarProps {
  inDashboard?: boolean;
}

const TSAppBar: React.FC<TSAppBarProps> = ({ inDashboard = false }) => {
  const dispatch: AppDispatch = useDispatch();
  // set up for current user profile
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const currentUser = useSelector(getUser);
  const isLoggedIn = Boolean(currentUser.id !== undefined);

  return (
    <Box sx={{ ...(inDashboard ? {} : { flexGrow: 1 }), width: "100%" }}>
      <AppBar
        position='fixed'
        color='custom'
        sx={{
          top: 0,
          boxShadow: "none",
          borderBottom: (theme) => `1px solid ${theme.palette.border.light}`,
          zIndex: (theme) => theme.zIndex.drawer + 1 // to make sure it stays above other components
        }}
      >
        <Box sx={{ px: { xs: 2, md: 0 } }}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              p: 0
            }}
          >
            <Logo />
            {isLoggedIn && <SearchBar />}
            <NavigationButtons isLoggedIn={isLoggedIn} />
            <MobileMenu isLoggedIn={isLoggedIn} />
          </Toolbar>
        </Box>
      </AppBar>
    </Box>
  );
};

export default TSAppBar;
