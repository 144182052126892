import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSortOption,
  setSortOption
} from "@features/InsitutionDatabase/resourceSlice";
import { FormControl, MenuItem, Select } from "@mui/material";
import { AppDispatch } from "src/services/store";

const Sort = () => {
  const dispatch: AppDispatch = useDispatch();

  const sortOption = useSelector(selectSortOption);

  const handleSortChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    dispatch(setSortOption(event.target.value));
  };

  return (
    <div className='flex items-center'>
      <hr className='flex-grow border-t border-gray-300' />
      <div className='flex items-center gap-2 ml-2 whitespace-nowrap'>
        <span className='text-black text-sm'>Sort by:</span>
        <FormControl fullWidth variant='outlined' size='small'>
          <Select
            id='sortOptions'
            value={sortOption}
            onChange={handleSortChange}
            displayEmpty
            sx={{
              borderRadius: "50px",
              "& .MuiSelect-select": {
                paddingLeft: "16px",
                paddingRight: "24px"
              }
            }}
          >
            <MenuItem value='recent'>Recent</MenuItem>
            <MenuItem value='oldest'>Oldest</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Sort;
