import getError from "@features/utils/get-error";
import { initialInstitutionMetrics } from "@features/utils/metrics-utils";
import {
  Institution,
  InstitutionMetrics,
  InstitutionName,
  Lab,
  LabMember,
  LabName
} from "@interfaces/lab";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

export const getLabInfo = createAsyncThunk(
  "lab/getLabInfo",
  async (labId: string, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const response = await api.get(`/lab/${labId}`, {
        cancelToken: source.token
      });

      return response.data?.data ?? null;
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const getInstituitionMembers = createAsyncThunk(
  "lab/getInstituitionMembers",
  async (
    params: {
      institutionId: string;
      page?: number;
      search?: string;
      limit?: number;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { institutionId, page = 1, search = "", limit = 10 } = params;

      const response = await api.get(`/institution/${institutionId}/members`, {
        params: { page, limit, search },
        cancelToken: source.token
      });

      // If a search was conducted, emit a message
      if (params.search) {
        const responseLength: number =
          response.data?.data?.members?.length || 0;
        const successMessage =
          responseLength > 0
            ? `Showing ${responseLength} ${responseLength === 1 ? "person" : "people"} matching "${params.search}"`
            : `No results for "${params.search}".`;
        const snackBarVariant = responseLength > 0 ? "success" : "warning";

        enqueueSnackbar(successMessage, { variant: snackBarVariant });
      }

      return {
        members: response.data?.data?.members ?? [],
        total_pages: response.data?.metadata?.total_pages ?? 0,
        totalCount: response.data?.metadata?.total_count ?? 0,
        current_page: page
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const getPrincipalInvestigators = createAsyncThunk(
  "lab/getPrincipalInvestigators",
  async (
    params: {
      institutionId: string;
      page: number;
      search?: string;
      limit?: number;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { institutionId, page = 1, search = "", limit = 10 } = params;

      const response = await api.get(
        `/principal_investigators/${institutionId}`,
        {
          params: { page, limit, search },
          cancelToken: source.token
        }
      );

      return {
        principalInvestigators: response.data?.data?.members ?? [],
        total_pages: response.data?.metadata?.total_pages ?? 0,
        totalCount: response.data?.metadata?.total_count ?? 0,
        current_page: page
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const removeLabMember = createAsyncThunk(
  "lab/removeLabMember",
  async (
    params: {
      labId: string;
      userId: string;
      firstName?: string;
      lastName?: string;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { labId, userId, firstName, lastName } = params;

      await api.delete(`/lab/${labId}/members/${userId}`, {
        cancelToken: source.token
      });
      if (firstName && lastName) {
        enqueueSnackbar(`${firstName} ${lastName} has been removed.`, {
          variant: "success"
        });
      } else {
        enqueueSnackbar(`Removed successfully.`, {
          variant: "success"
        });
      }
      return userId;
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const updateLab = createAsyncThunk(
  "lab/updateLab",
  async (
    params: {
      labId: string;
      data: { description?: string; research_interests?: string[] };
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { labId, data } = params;
      await api.patch(`/lab/${labId}`, data, {
        cancelToken: source.token
      });

      return data;
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const addNewMember = createAsyncThunk(
  "lab/addNewMember",
  async (
    data: {
      user_id: string;
      lab_id: string;
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { lab_id, user_id } = data;
      await api.post(`/lab/${lab_id}/members`, data, {
        cancelToken: source.token
      });
      enqueueSnackbar("Joined successfully", { variant: "success" });

      return user_id;
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const inviteLabMember = createAsyncThunk(
  "lab/inviteLabMember",
  async (
    data: {
      invited_by_user_id: string;
      email: string;
      first_name: string;
      last_name: string;
      lab_id: string;
      institution_id?: string;
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { lab_id } = data;
      await api.post(`/lab/${lab_id}/members/new`, data, {
        cancelToken: source.token
      });

      enqueueSnackbar(
        `"${data.first_name} ${data.last_name}" has been successfully invited to this lab`,
        {
          variant: "success"
        }
      );
      return data;
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const getInstitutionName = createAsyncThunk(
  "institution/name",
  async (
    params: {
      institutionDomain: string;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());
    const { institutionDomain } = params;

    try {
      const response = await api.get(`/institution/name/${institutionDomain}`);
      return {
        institutionName: response?.data?.data.institution
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const getInstitutionById = createAsyncThunk(
  "institution/byId",
  async (
    params: {
      institutionId: string;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());
    const { institutionId } = params;

    try {
      const response = await api.get(`/institution/${institutionId}`);
      return {
        institution: response?.data?.data.institution
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const requestAccessToInstitutionFeatures = createAsyncThunk(
  "institution/requestAccess",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post(`/institution/request_access`);
      enqueueSnackbar("We have received your request. Thank you.", {
        variant: "success"
      });
      return response.data;
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError.error);
    }
  }
);

export const getInstitutionLabNames = createAsyncThunk(
  "institution/labName",
  async (
    params: {
      institutionId: string;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());
    const { institutionId } = params;

    try {
      const response = await api.get(`/institution/${institutionId}/lab_name`);
      return { institutionLabNames: response?.data?.data.lab_names };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const getInstitutionDomains = createAsyncThunk(
  "institution/domains",
  async (_, { rejectWithValue, signal }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const response = await api.get(`/institution/domains`);
      return { institutionDomains: response?.data?.data.institution_domains };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const getInstitutionMetrics = createAsyncThunk(
  "getInstitutionMetrics",
  async (
    params: { institutionId: string; timeframe: string },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());
    try {
      const { institutionId, timeframe } = params;
      const response = await api.get(`/institution/${institutionId}/metrics`, {
        params: { timeframe },
        cancelToken: source.token
      });
      return { institutionMetrics: response?.data.data };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);
interface LabState {
  lab: Lab | null;
  loading: {
    main: boolean;
    uniMembers: boolean;
    principalInvestigators: boolean;
    removeMember: boolean;
    addMember: Record<string, boolean>;
    institution: boolean;
    institutionNames: boolean;
    institutionLabNames: boolean;
    institutionDomains: boolean;
    institutionMetrics: boolean;
  };
  error: string | null;
  uniMembers: LabMember[];
  principalInvestigators: LabMember[];
  page: number;
  totalPages: number;
  query: string;
  totalCount: number;
  institution: Institution;
  institutionName: InstitutionName;
  institutionLabNames: LabName[];
  institutionDomains: [];
  institutionMetrics: InstitutionMetrics;
}

const initialState: LabState = {
  lab: null,
  loading: {
    main: false,
    uniMembers: false,
    principalInvestigators: false,
    removeMember: false,
    addMember: {},
    institution: false,
    institutionNames: false,
    institutionLabNames: false,
    institutionDomains: false,
    institutionMetrics: false
  },
  error: null,
  uniMembers: [],
  principalInvestigators: [],
  page: 1,
  totalPages: 0,
  totalCount: 0,
  query: "",
  institution: {
    institution_id: "",
    institution_name: "",
    is_partner: undefined,
    partnership: {
      default_public: undefined,
      end_datetime: "",
      institution_id: "",
      start_datetime: ""
    }
  },
  institutionName: {
    institution_id: "",
    institution_name: ""
  },
  institutionLabNames: [],
  institutionDomains: [],
  institutionMetrics: initialInstitutionMetrics
};

export const labSlice = createSlice({
  name: "lab",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLabInfo.pending, (state) => {
        state.loading = {
          ...state.loading,
          main: true
        };
        state.error = null;
      })
      .addCase(
        getLabInfo.fulfilled,
        (state, action: PayloadAction<Lab | null>) => {
          state.loading = {
            ...state.loading,
            main: false
          };
          state.lab = action.payload;
        }
      )
      .addCase(getLabInfo.rejected, (state) => {
        state.loading = {
          ...state.loading,
          main: false
        };
      })
      .addCase(getInstituitionMembers.pending, (state) => {
        state.loading = {
          ...state.loading,
          uniMembers: true
        };
        state.error = null;
      })
      .addCase(
        getInstituitionMembers.fulfilled,
        (
          state,
          action: PayloadAction<{
            members: LabMember[];
            total_pages: number;
            current_page: number;
            totalCount: number;
          }>
        ) => {
          state.loading = {
            ...state.loading,
            uniMembers: false
          };
          state.uniMembers = action.payload.members;
          state.totalPages = action.payload.total_pages;
          state.totalCount = action.payload.totalCount;
          state.page = action.payload.current_page;
        }
      )
      .addCase(getInstituitionMembers.rejected, (state) => {
        state.loading = {
          ...state.loading,
          principalInvestigators: false
        };
        state.principalInvestigators = [];
        state.totalPages = 0;
        state.totalCount = 0;
        state.page = 1;
      })
      .addCase(getPrincipalInvestigators.pending, (state) => {
        state.loading = {
          ...state.loading,
          principalInvestigators: true
        };
        state.error = null;
      })
      .addCase(
        getPrincipalInvestigators.fulfilled,
        (
          state,
          action: PayloadAction<{
            principalInvestigators: LabMember[];
            total_pages: number;
            current_page: number;
            totalCount: number;
          }>
        ) => {
          state.loading = {
            ...state.loading,
            principalInvestigators: false
          };
          state.principalInvestigators = action.payload.principalInvestigators;
          state.totalPages = action.payload.total_pages;
          state.totalCount = action.payload.totalCount;
          state.page = action.payload.current_page;
        }
      )
      .addCase(getPrincipalInvestigators.rejected, (state) => {
        state.loading = {
          ...state.loading,
          principalInvestigators: false
        };
        state.principalInvestigators = [];
        state.totalPages = 0;
        state.totalCount = 0;
        state.page = 1;
      })
      .addCase(getInstitutionName.pending, (state) => {
        state.loading = {
          ...state.loading,
          institutionNames: true
        };
        state.error = null;
      })
      .addCase(
        getInstitutionName.fulfilled,
        (
          state,
          action: PayloadAction<{ institutionName: InstitutionName }>
        ) => {
          state.loading = {
            ...state.loading,
            institutionNames: false
          };
          state.institutionName = action.payload.institutionName;
        }
      )
      .addCase(getInstitutionName.rejected, (state) => {
        state.loading = {
          ...state.loading,
          institutionNames: false
        };
        state.institutionName = { institution_name: "", institution_id: "" };
      })
      .addCase(getInstitutionById.pending, (state) => {
        state.loading = {
          ...state.loading,
          institution: true
        };
        state.error = null;
      })
      .addCase(
        getInstitutionById.fulfilled,
        (state, action: PayloadAction<{ institution: Institution }>) => {
          state.loading = {
            ...state.loading,
            institution: false
          };
          state.institution = action.payload.institution;
        }
      )
      .addCase(getInstitutionById.rejected, (state) => {
        state.loading = {
          ...state.loading,
          institution: false
        };
        state.institution = {
          institution_name: "",
          institution_id: "",
          is_partner: undefined,
          partnership: {
            default_public: true,
            end_datetime: "",
            institution_id: "",
            start_datetime: ""
          }
        };
      })
      .addCase(getInstitutionLabNames.pending, (state) => {
        state.loading = {
          ...state.loading,
          institutionLabNames: true
        };
        state.error = null;
      })
      .addCase(
        getInstitutionLabNames.fulfilled,
        (state, action: PayloadAction<{ institutionLabNames: LabName[] }>) => {
          state.loading = {
            ...state.loading,
            institutionLabNames: false
          };
          state.institutionLabNames = action.payload.institutionLabNames;
        }
      )
      .addCase(getInstitutionLabNames.rejected, (state) => {
        state.loading = {
          ...state.loading,
          institutionLabNames: false
        };
        state.institutionLabNames = [];
      })
      .addCase(getInstitutionDomains.pending, (state) => {
        state.loading = {
          ...state.loading,
          institutionDomains: true
        };
        state.error = null;
      })
      .addCase(
        getInstitutionDomains.fulfilled,
        (state, action: PayloadAction<{ institutionDomains: [] }>) => {
          state.loading = {
            ...state.loading,
            institutionDomains: false
          };
          state.institutionDomains = action.payload.institutionDomains;
        }
      )
      .addCase(getInstitutionDomains.rejected, (state) => {
        state.loading = {
          ...state.loading,
          institutionDomains: false
        };
        state.institutionDomains = [];
      })
      .addCase(removeLabMember.fulfilled, (state, action) => {
        if (!state.lab) return;
        state.lab.members = state.lab.members.filter(
          (member) => member.user_id !== action.payload
        );
      })
      .addCase(addNewMember.pending, (state, action) => {
        state.loading.addMember = {
          ...state.loading.addMember,
          [action.meta.arg.user_id]: true
        };
      })
      .addCase(
        addNewMember.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading.addMember = {
            ...state.loading.addMember,
            [action.payload]: false
          };
          if (!state.lab) return;
          const addedUser = state.uniMembers.find(
            (member) => member.user_id === action.payload
          );
          if (addedUser) {
            state.lab.members.push(addedUser);
          }
        }
      )
      .addCase(addNewMember.rejected, (state, action) => {
        state.loading.addMember = {
          ...state.loading.addMember,
          [action.meta.arg.user_id]: false
        };
      })
      .addCase(updateLab.pending, (state) => {
        state.loading = {
          ...state.loading,
          main: false
        };
        state.error = null;
      })
      .addCase(
        updateLab.fulfilled,
        (
          state,
          action: PayloadAction<{
            description?: string;
            research_interests?: string[];
          }>
        ) => {
          state.loading = {
            ...state.loading,
            main: false
          };
          if (!state.lab) return;
          if (action.payload.description) {
            state.lab.description = action.payload.description;
          }
          if (action.payload.research_interests) {
            state.lab.research_interests = action.payload.research_interests;
          }
        }
      )
      .addCase(updateLab.rejected, (state) => {
        state.loading = {
          ...state.loading,
          main: false
        };
      })
      .addCase(getInstitutionMetrics.pending, (state) => {
        state.loading = { ...state.loading, institutionMetrics: true };
        state.error = null;
      })
      .addCase(
        getInstitutionMetrics.fulfilled,
        (
          state,
          action: PayloadAction<{
            institutionMetrics: InstitutionMetrics;
          }>
        ) => {
          state.loading = {
            ...state.loading,
            institutionMetrics: false
          };
          state.institutionMetrics = action.payload.institutionMetrics;
        }
      )
      .addCase(getInstitutionMetrics.rejected, (state) => {
        (state.loading = {
          ...state.loading,
          institutionMetrics: false
        }),
          (state.institutionMetrics = initialInstitutionMetrics);
      });
  }
});

export const { name, actions } = labSlice;

export const { setPage, setQuery } = actions;

const getSlice = (state: RootState) => state[name];

export const selectLab = createSelector(
  getSlice,
  (slice) => slice?.lab || null
);
export const selectLabLoading = createSelector(
  getSlice,
  (slice) =>
    slice?.loading || {
      main: false,
      uniMembers: false,
      removeMember: false,
      addMember: {}
    }
);
export const selectLabError = createSelector(getSlice, (slice) => slice?.error);

export const selectInstituitionMembers = createSelector(
  getSlice,
  (slice) => slice?.uniMembers || []
);
export const selectPrincipalInvestigators = createSelector(
  getSlice,
  (slice) => slice?.principalInvestigators || []
);
export const selectLabMembers = createSelector(
  getSlice,
  (slice) => slice?.lab?.members || []
);

export const selectInstutionName = createSelector(
  getSlice,
  (slice) => slice?.lab?.institution_name || ""
);

export const selectPage = createSelector(getSlice, (slice) => slice?.page || 1);
export const selectTotalPages = createSelector(
  getSlice,
  (slice) => slice?.totalPages || 0
);
export const selectQuery = createSelector(
  getSlice,
  (slice) => slice.query || ""
);
export const selectTotalCount = createSelector(
  getSlice,
  (slice) => slice?.totalCount
);

export const selectFetchInstLoading = createSelector(
  getSlice,
  (slice) =>
    slice?.loading || {
      main: false,
      uniMembers: false,
      removeMember: false,
      institutionNames: false,
      institutionLabNames: false,
      institutionMetrics: false,
      addMember: {}
    }
);

export const selectInstutionNameByDomain = createSelector(
  getSlice,
  (slice) => slice?.institutionName
);

export const selectInstitutionById = createSelector(
  getSlice,
  (slice) => slice?.institution
);

export const selectInstitutionLabNames = createSelector(
  getSlice,
  (slice) => slice?.institutionLabNames || []
);

export const selectInstitutionDomainNames = createSelector(
  getSlice,
  (slice) => slice?.institutionDomains || []
);

export const selectInstitutionMetrics = createSelector(
  getSlice,
  (slice) => slice?.institutionMetrics
);
