import React from "react";

import { PrimaryButton, TextButton } from "@components/base/Button";
import { Input } from "@components/base/Input";
import { BodyLarge, BodySmall } from "@components/base/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, Chip, Drawer, IconButton } from "@mui/material";

interface FilterSidebarProps {
  open: boolean;
  onClose: () => void;
  filterOptions: string[];
  options: Record<string, string[]>;
  selectedFilters: Record<string, string[]>;
  onSelectionChange: (filter: string, value: string[]) => void;
  onDeleteTag: (filter: string, tag: string) => void;
  totalResults: number;
  onClearAll: () => void;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  open,
  onClose,
  filterOptions,
  options,
  selectedFilters,
  onSelectionChange,
  onDeleteTag,
  totalResults,
  onClearAll
}) => {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "400px",
          marginTop: 8
        }
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={2}
        >
          <BodySmall fontWeight='bold'>Filter</BodySmall>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          component='form'
          noValidate
          autoComplete='off'
          display='flex'
          flexDirection='column'
          gap={3}
        >
          {filterOptions.map((filter) => {
            const filteredOptions = (options[filter] || []).filter(
              (option) => option !== "No options available"
            );

            return (
              <Box key={filter}>
                <BodyLarge mb={1} sx={{ color: "text.secondary" }}>
                  {filter}
                </BodyLarge>
                <Autocomplete
                  multiple
                  options={filteredOptions}
                  value={selectedFilters[filter] || []}
                  onChange={(event, newValue) =>
                    onSelectionChange(filter, newValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => onDeleteTag(filter, option)}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <Input
                      {...params}
                      placeholder={
                        selectedFilters[filter]?.length
                          ? ""
                          : `Select ${filter}`
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
            );
          })}
        </Box>

        <Box textAlign='center' marginTop={3}>
          <PrimaryButton
            size='large'
            fullWidth
            sx={{
              textTransform: "none",
              marginBottom: 2
            }}
            onClick={onClose}
          >
            See {totalResults} Results
          </PrimaryButton>

          <TextButton
            onClick={onClearAll}
            sx={{ textTransform: "none", fontWeight: "bold" }}
          >
            Clear All
          </TextButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterSidebar;
