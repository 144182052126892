import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Input } from "@components/base/Input";
import { BodyLarge, BodySmall } from "@components/base/Typography";
import { getUser } from "@features/Auth/authSlice";
import {
  getRoomMessages,
  markMessagesAsRead,
  selectRoomMessages
} from "@features/Messaging/messagingSlice";
import {
  socketSendMessage,
  socketStartTyping,
  socketStopTyping
} from "@features/sockets/socket-events";
import FormatMessageTime from "@features/utils/formatMessageTime";
import { BASE_S3_URL } from "@features/utils/manage-file";
import { LabMember } from "@interfaces/lab";
import { Message } from "@interfaces/messaging";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  useMediaQuery
} from "@mui/material";
import DefaultProfile from "@static/icons/default-profile.png";
import { AppDispatch, RootState } from "src/services/store";
interface ChatProps {
  newChatUser: LabMember | undefined;
}

const ChatView: React.FC<ChatProps> = ({ newChatUser }) => {
  const isInitialMount = useRef(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const newChatUserFromContact = location.state?.newChatUser;
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const roomMessages = useSelector(selectRoomMessages);
  const previousRoomMessagesLength = useRef(roomMessages?.length || 0);
  const newMessages = useSelector((state: RootState) => state.socket.messages);
  const typingUsers = useSelector(
    (state: RootState) => state.socket.typingUsers
  );
  const currentRoomId = useSelector(
    (state: RootState) => state.socket.currentRoom
  );
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [messageSent, setMessageSent] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const renderTypingIndicator = () => {
    const otherTypingUsers = typingUsers.filter(
      (userFirstName) => userFirstName !== currentUser?.firstName
    );

    if (otherTypingUsers.length === 0) return null;

    if (otherTypingUsers.length === 1) {
      return (
        <div className='text-sm text-gray-500'>
          {otherTypingUsers[0]}is typing...
        </div>
      );
    }

    return (
      <div className='text-sm text-gray-500'>Multiple users are typing...</div>
    );
  };

  const handleSendMessage = () => {
    if (currentRoomId) {
      if (message.trim()) {
        dispatch(
          socketSendMessage({
            room_id: currentRoomId,
            sender_id: currentUser.id,
            message
          })
        );
        setMessage("");
        setMessageSent(true);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    setMessageSent(false);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (currentRoomId && currentUser) {
      dispatch(
        socketStartTyping({
          room_id: currentRoomId,
          user: currentUser.firstName
        })
      );
    }

    const newTimeout = setTimeout(() => {
      if (currentRoomId && currentUser) {
        dispatch(
          socketStopTyping({
            room_id: currentRoomId,
            user: currentUser.firstName
          })
        );
      }
    }, 1000);

    setTypingTimeout(newTimeout);
  };

  useEffect(() => {
    setMessages(roomMessages);
    dispatch(
      markMessagesAsRead({ readerId: currentUser.id, roomId: currentRoomId })
    );
  }, [roomMessages, newMessages, dispatch]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (previousRoomMessagesLength.current !== (roomMessages?.length || 0)) {
      dispatch(getRoomMessages({ roomId: currentRoomId }));
      previousRoomMessagesLength.current = roomMessages?.length || 0;
    }
  }, [
    messageSent,
    dispatch,
    newMessages,
    currentRoomId,
    roomMessages,
    window.history,
    window.location.pathname
  ]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  }, [messages, newMessages]);

  useEffect(() => {
    setMessages(roomMessages);
    dispatch(getRoomMessages({ roomId: currentRoomId }));
  }, [messageSent, newMessages]);

  useEffect(() => {
    setMessages(roomMessages);
  }, [roomMessages, newMessages]);

  let lastDisplayedGroup = "";

  useEffect(() => {
    return () => {
      window.history.replaceState({}, "", window.location.pathname);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      window.history.replaceState({}, "", window.location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          height: isMobile ? "calc(100% - 120px)" : "calc(100% - 160px)",
          overflowY: "auto",
          padding: 2
        }}
      >
        {messages.length > 0 ? (
          messages.map((message, index) => {
            const currentGroup = FormatMessageTime(message.created_at);
            const showDateDivider = currentGroup !== lastDisplayedGroup;

            if (showDateDivider) {
              lastDisplayedGroup = currentGroup;
            }

            return (
              <div key={index}>
                {showDateDivider && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginY: 2
                    }}
                  >
                    <Divider sx={{ flex: 1 }} />
                    <BodySmall color='textSecondary' sx={{ paddingX: 2 }}>
                      {currentGroup}
                    </BodySmall>
                    <Divider sx={{ flex: 1 }} />
                  </Box>
                )}

                <Box
                  sx={{ marginY: 1, display: "flex", alignItems: "flex-start" }}
                >
                  <Avatar
                    src={
                      !message.sender?.profile_photo_thumbnail_key ||
                      message.sender.profile_photo_thumbnail_key.length === 0
                        ? DefaultProfile
                        : `${BASE_S3_URL}${message.sender.profile_photo_thumbnail_key}`
                    }
                    sx={{ marginRight: 1 }}
                  />
                  <Box>
                    <BodySmall
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "0.95rem"
                      }}
                    >
                      <Link
                        href={`/dashboard/member/${message.sender_id}`}
                        underline='hover'
                        color={"inherit"}
                      >
                        <strong>{`${message.sender?.first_name} ${message.sender?.last_name}`}</strong>
                      </Link>
                      <BodySmall
                        component='span'
                        variant='body2'
                        color='textSecondary'
                        sx={{ marginLeft: 2, fontSize: "0.75rem" }}
                      >
                        {new Date(message.created_at).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true
                          }
                        )}{" "}
                      </BodySmall>
                    </BodySmall>
                    <BodySmall sx={{ fontSize: "0.875rem" }}>
                      {message.content
                        ? message.content.split("\n").map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                        : "No message content"}
                    </BodySmall>
                  </Box>
                </Box>
              </div>
            );
          })
        ) : newChatUser ? (
          <BodyLarge>
            Start a new conversation with{" "}
            <strong>{newChatUser.first_name}</strong>
          </BodyLarge>
        ) : newChatUserFromContact ? (
          <BodyLarge>
            Start a new conversation with{" "}
            <strong>{newChatUserFromContact.first_name}</strong>
          </BodyLarge>
        ) : (
          <BodyLarge>No conversation selected</BodyLarge>
        )}
        <div ref={messagesEndRef} />
      </Box>

      <Box
        sx={{
          padding: 2
        }}
      >
        {renderTypingIndicator()}
        <Input
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          placeholder='Write a message ...'
          sx={{
            borderColor: "border.main",
            backgroundColor: "background.gray",
            borderRadius: "13px"
          }}
          value={message}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color='primary'
                  onClick={handleSendMessage}
                  edge='end'
                  sx={{ padding: "10px" }}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Box>
  );
};
export default ChatView;
