import { getRoomMessages } from "@features/Messaging/messagingSlice";
import { addNotification } from "@features/Notifications/notificationSlice";
import { Message } from "@interfaces/messaging";
import { Notification } from "@interfaces/notifications";
import { createAction } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { Socket } from "socket.io-client";
import { AppDispatch } from "src/services/store";

import {
  addTypingUser,
  joinRoom,
  leaveRoom,
  messageReceived,
  removeTypingUser,
  socketConnected,
  socketDisconnected
} from "./socketSlice";

export interface SocketMessagePayload {
  room_id: string | null;
  sender_id: string;
  message: string;
}

export interface SocketCreateRoomPayload {
  room_type: string;
  creator_id: string;
  participant_id: string | undefined;
}

export interface RoomCreatedPayload {
  room_id: string;
  room_type: string;
  creator_id: string;
  participant_id: string;
  created_at: string;
}

export interface MessageReceivedPayload {
  message_id: string;
  room_id: string;
  sender_id: string;
  sender: {
    first_name: string;
    last_name: string;
  };
  content: string;
  created_at: string;
}

export const SOCKET_EVENTS = {
  JOIN_ROOM: "join_room",
  LEAVE_ROOM: "leave_room",
  SEND_MESSAGE: "send_message",
  START_TYPING: "typing",
  STOP_TYPING: "stopped_typing",
  CREATE_ROOM: "create_room"
} as const;

// implementations will be done later
export const setupSocketHandlers = (socket: Socket, dispatch: AppDispatch) => {
  socket.on("connect", () => {
    console.log("Connected to server");
    dispatch(socketConnected());
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
    dispatch(socketDisconnected());
  });

  socket.on(
    "room_created",
    (data: {
      room_id: string;
      room_type: string;
      creator_id: string;
      participant_id: string;
      created_at: string;
    }) => {
      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-empty-function
      socket.emit("join_room", { room_id: data.room_id }, () => { });
      dispatch(joinRoom(data.room_id));
      dispatch(getRoomMessages({ roomId: data.room_id }));
    }
  );

  socket.on("room_joined", (data: { room_id: string }) => {
    dispatch(joinRoom(data.room_id));
    dispatch(getRoomMessages({ roomId: data.room_id }));
  });

  socket.on("room_left", () => {
    dispatch(leaveRoom());
  });

  socket.on("message_received", (data: Message) => {
    dispatch(messageReceived(data));
  });

  socket.on("typing", (data: { user: string; room: string }) => {
    dispatch(addTypingUser(data.user));
  });

  socket.on("user_stopped_typing", (data: { user: string }) => {
    dispatch(removeTypingUser(data.user));
  });

  socket.on("new_notification", (data: Notification) => {
    dispatch(addNotification(data));
    enqueueSnackbar(`${data.name || "Someone"} just sent you a notification`, {
      variant: "info"
    });
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emitSocketEvent = (socket: Socket, event: string, data: any) => {
  if (socket.connected) {
    socket.emit(event, data);
  } else {
    console.warn("Socket not connected, cannot emit");
  }
};

export const socketCreateRoom = createAction(
  "socket/socketCreateRoom",
  (payload: SocketCreateRoomPayload) => ({
    payload,
    meta: {
      socket: {
        channel: SOCKET_EVENTS.CREATE_ROOM
      }
    }
  })
);

export const socketJoinRoom = createAction(
  "socket/socketJoinRoom",
  (roomId: string) => ({
    payload: roomId,
    meta: {
      socket: {
        channel: SOCKET_EVENTS.JOIN_ROOM,
        room: roomId
      }
    }
  })
);

export const socketLeaveRoom = createAction(
  "socket/socketLeaveRoom",
  (roomId: string) => ({
    payload: roomId,
    meta: {
      socket: {
        channel: SOCKET_EVENTS.LEAVE_ROOM,
        room: roomId
      }
    }
  })
);

export const socketSendMessage = createAction(
  "socket/socketSendMessage",
  (payload: SocketMessagePayload) => ({
    payload,
    meta: {
      socket: {
        channel: SOCKET_EVENTS.SEND_MESSAGE
      }
    }
  })
);

export const socketStartTyping = createAction(
  "socket/socketStartTyping",
  (params: { room_id: string; user: string }) => {
    return {
      payload: params,
      meta: {
        socket: {
          channel: SOCKET_EVENTS.START_TYPING
        }
      }
    };
  }
);

export const socketStopTyping = createAction(
  "socket/socketStopTyping",
  (params: { room_id: string; user: string }) => ({
    payload: params,
    meta: {
      socket: {
        channel: SOCKET_EVENTS.STOP_TYPING
      }
    }
  })
);
