import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { SecondaryButton } from "@components/base/Button";
import { OutlinedCard } from "@components/base/Card";
import Sort from "@components/Sort/Sort";
import { getUser } from "@features/Auth/authSlice";
import EntryList from "@features/Posts/components/EntryList";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack } from "@mui/material";
import { DashboardContent } from "src/layout/dashboard";

const InstitutionalHomepage = () => {
  const currentUser = useSelector(getUser);
  return (
    <DashboardContent className='lg:px-36'>
      <Stack spacing={3}>
        <Box>
          <OutlinedCard
            sx={{
              "&:hover": { boxShadow: "none" }
            }}
            title={
              "Discover and share animal models, cell lines & other resources at your institution"
            }
            subtitle={
              "Or share your own resources to collaborate with other researchers!"
            }
          >
            <div className='flex flex-col md:flex-row gap-4 py-1 w-full'>
              <Link to='/dashboard/create' className='w-auto'>
                <SecondaryButton color='primary' startIcon={<AddIcon />}>
                  Create a post
                </SecondaryButton>
              </Link>
              <Link to='institution-database' className='w-auto'>
                <SecondaryButton color='primary' startIcon={<SearchIcon />}>
                  Search database
                </SecondaryButton>
              </Link>
            </div>
          </OutlinedCard>
        </Box>
        <Sort />
        <EntryList
          instId={currentUser.institutionId}
          scrollContainer='#dashboard-content'
          renderSource='institution'
        />
      </Stack>
    </DashboardContent>
  );
};

export default InstitutionalHomepage;
