import React from "react";

import { Typography as MuiTypography, TypographyProps } from "@mui/material";

interface BaseTypographyProps extends TypographyProps {
  children: React.ReactNode;
  gutterBottom?: boolean;
  component?: React.ElementType;
}

// Heading Components
export const H1 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='h1' {...props}>
    {children}
  </MuiTypography>
);

export const H2 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='h2' {...props}>
    {children}
  </MuiTypography>
);

export const H3 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='h3' {...props}>
    {children}
  </MuiTypography>
);

export const H4 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='h4' {...props}>
    {children}
  </MuiTypography>
);

export const H5 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='h5' {...props}>
    {children}
  </MuiTypography>
);

export const H6 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='h6' {...props}>
    {children}
  </MuiTypography>
);

export const Subtitle1 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='subtitle1' {...props}>
    {children}
  </MuiTypography>
);

export const Subtitle2 = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='subtitle2' {...props}>
    {children}
  </MuiTypography>
);

// Body Text Components
export const BodyLarge = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='body1' {...props}>
    {children}
  </MuiTypography>
);

export const BodySmall = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='body2' {...props}>
    {children}
  </MuiTypography>
);

// Lead Text Components
export const LeadLarge = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='largeLead' {...props}>
    {children}
  </MuiTypography>
);

export const LeadSmall = ({ children, ...props }: BaseTypographyProps) => (
  <MuiTypography variant='smallLead' {...props}>
    {children}
  </MuiTypography>
);
