import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryButton } from "@components/base/Button";
import { Input } from "@components/base/Input";
import {
  BodyLarge,
  BodySmall,
  LeadLarge,
  LeadSmall
} from "@components/base/Typography";
import { getUser } from "@features/Auth/authSlice";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack
} from "@mui/material";
import inbox from "@static/envelope-animation.png";
import { useFormik } from "formik";
import { AppDispatch } from "src/services/store";
import * as yup from "yup";

import {
  getInstitutionName,
  inviteLabMember,
  selectInstutionNameByDomain,
  selectLab
} from "../labslice";

interface InviteLabMemberDialogProps {
  open: boolean;
  handleCloseInviteDialog: () => void;
  title: string;
}

interface InviteMemberFormValues {
  first_name: string;
  last_name: string;
  email: string;
  lab_id: string;
  invited_by_user_id: string;
  institution_id?: string;
}

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required")
});

const InviteLabMemberDialog: FC<InviteLabMemberDialogProps> = ({
  open,
  handleCloseInviteDialog,
  title
}) => {
  const dispatch: AppDispatch = useDispatch();

  const [domainError, setDomainError] = useState("");

  const currentUser = useSelector(getUser);
  const lab = useSelector(selectLab);
  const institution = useSelector(selectInstutionNameByDomain);
  const [institutionLoaded, setInstitutionLoaded] = useState(false);

  // State to manage success screen visibility
  const [isInviteSent, setIsInviteSent] = useState(false);

  const formik = useFormik<InviteMemberFormValues>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      invited_by_user_id: currentUser.id,
      lab_id: lab ? lab.lab_id : "",
      ...(lab?.institution_id ? { institution_id: lab.institution_id } : {})
    },
    validationSchema,
    onSubmit: async (values) => {
      const resultAction = await dispatch(inviteLabMember(values));
      if (inviteLabMember.fulfilled.match(resultAction)) {
        setIsInviteSent(true);
      }
    }
  });

  const fetchInstitutionName = async () => {
    const email = formik.values.email;
    const domain = email.substring(email.lastIndexOf("@") + 1);

    if (domain) {
      await dispatch(getInstitutionName({ institutionDomain: domain }));
      setInstitutionLoaded(true);
    }
  };

  useEffect(() => {
    if (institutionLoaded) {
      setInstitutionLoaded(false);
      if (institution.institution_name) {
        formik.handleSubmit();
      } else {
        setDomainError(
          "This email is not recognized. Please verify it or contact support."
        );
      }
    }
  }, [institution, institutionLoaded]);

  useEffect(() => {
    if (lab) {
      formik.setFieldValue("lab_id", lab.lab_id);
      if (lab.institution_id) {
        formik.setFieldValue("institution_id", lab.institution_id);
      }
    }
  }, [lab]);

  const handleDialogClose = () => {
    handleCloseInviteDialog();
    setIsInviteSent(false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth='sm' fullWidth>
      <Box sx={{ py: 1 }}>
        {isInviteSent ? (
          <DialogTitle>
            <Stack direction='row' alignItems='center'>
              <LeadLarge fontWeight={"bold"} display={"none"}>
                {title}
              </LeadLarge>
            </Stack>
          </DialogTitle>
        ) : (
          <DialogTitle
            sx={{ borderBottom: "1px solid", borderColor: "border.dark" }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent={!isInviteSent ? "space-between" : "flex-end"}
            >
              <LeadLarge fontWeight={"bold"}>{title}</LeadLarge>
              <IconButton onClick={handleDialogClose}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
        )}
        <DialogContent>
          {isInviteSent ? (
            <Stack
              alignItems='center'
              spacing={8}
              height={"100%"}
              justifyContent={"center"}
            >
              <LeadSmall>Invitation Sent!</LeadSmall>
              <Box
                sx={{ width: 100, height: 80 }}
                component={"img"}
                src={inbox}
              />
              <Stack alignItems={"center"} width={"100%"}>
                <PrimaryButton
                  sx={{
                    width: { xs: "100%", md: "80%" }
                  }}
                  fullWidth
                  size='large'
                  onClick={handleDialogClose}
                >
                  Done
                </PrimaryButton>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing={5} mt={2}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <BodyLarge>First Name</BodyLarge>
                  <Input
                    fullWidth
                    id='first_name'
                    name='first_name'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name
                        ? formik.errors.first_name || ""
                        : ""
                    }
                  />
                </Stack>
                <Stack spacing={2}>
                  <BodyLarge>Last Name</BodyLarge>
                  <Input
                    fullWidth
                    id='last_name'
                    name='last_name'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name ? formik.errors.last_name : ""
                    }
                  />
                </Stack>
                <Stack spacing={2}>
                  <BodyLarge>Email</BodyLarge>
                  <Input
                    fullWidth
                    id='email'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email ? formik.errors.email : ""}
                  />
                  {domainError && (
                    <BodySmall variant='body2' className='text-red-500'>
                      {domainError}
                    </BodySmall>
                  )}
                </Stack>
              </Stack>
              <Stack alignItems='center' mt={5}>
                <PrimaryButton
                  sx={{
                    width: { xs: "100%", md: "80%" },
                    height: "60px"
                  }}
                  size='large'
                  type='submit'
                  onClick={fetchInstitutionName}
                >
                  Send Invite
                </PrimaryButton>
              </Stack>
            </Stack>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default InviteLabMemberDialog;
