import getError from "@features/utils/get-error";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

interface ContactUsForm {
  fullname: string;
  institutionName: string;
  institutionEmail: string;
  jobTitle?: string;
  message: string;
}

interface ContactUsState {
  loading: boolean;
  error: unknown;
  success: boolean;
}

// Initial state
const initialState: ContactUsState = {
  loading: false,
  error: null,
  success: false
};

// Async thunk for submitting the contact form
export const submitContactForm = createAsyncThunk(
  "contact/submitContactForm",
  async (formData: ContactUsForm, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.post("/contact_us", formData, {
        cancelToken: source.token
      });
      enqueueSnackbar("Your message has been sent successfully!", {
        variant: "success"
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

// Contact us slice
export const contact = createSlice({
  name: "contact",
  initialState,
  reducers: {
    // Reset form state for a fresh start or after submission
    resetContactState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  }
});

// Exporting the actions
export const { resetContactState } = contact.actions;

// Selector functions
const getContactSlice = (state: RootState) => state[contact.name];

// Selector: Get loading state
export const getContactLoading = createSelector(
  getContactSlice,
  (slice) => slice?.loading
);

// Selector: Get error state
export const getContactError = createSelector(
  getContactSlice,
  (slice) => slice?.error
);

// Selector: Get success state
export const getContactSuccess = createSelector(
  getContactSlice,
  (slice) => slice?.success
);

export default contact.reducer;
