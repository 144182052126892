import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import NotificationsCard from "@components/AppBar/NotificationsCard";
import { PrimaryButton } from "@components/base/Button";
import { BodySmall } from "@components/base/Typography";
import { logout } from "@features/Auth/authSlice";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Box, Menu, MenuItem } from "@mui/material";
import about from "@static/icons/about.svg";
import home from "@static/icons/home.svg";
import logoutSVG from "@static/icons/logout.svg";
import messaging from "@static/icons/messaging.svg";
import notification from "@static/icons/notification.svg";
import { ENABLE_MESSAGING_FEATURE } from "src/config";
import { AppDispatch } from "src/services/store";

interface MobileMenuProps {
  isLoggedIn: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLoginLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    handleMobileMenuClose();

    if (isLoggedIn) {
      const logoutResponse = await dispatch(logout());
      if (logoutResponse.payload.logoutUrl) {
        return (window.location.href = logoutResponse.payload.logoutUrl);
      }
      navigate("/");
    } else {
      setTimeout(() => navigate("/get-started"), 0);
    }
  };

  const handleNavigation = (path: string) => {
    handleMobileMenuClose();
    setTimeout(() => navigate(path), 0);
  };

  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <PrimaryButton
        sx={{ p: 0, minWidth: "unset", backgroundColor: "transparent" }}
        size='small'
        aria-label='show more'
        aria-controls='mobile-menu'
        aria-haspopup='true'
        onClick={handleMobileMenuOpen}
        color='inherit'
      >
        <MoreIcon color='primary' />
      </PrimaryButton>
      <Menu
        id='mobile-menu'
        anchorEl={mobileMoreAnchorEl}
        keepMounted
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => handleNavigation(isLoggedIn ? "/dashboard/home" : "/")}
          className='flex flex-col items-center'
        >
          <img src={home} alt='Home' className='w-5 h-5' />
          <BodySmall fontSize={12}>Home</BodySmall>
        </MenuItem>
        <MenuItem
          onClick={() => handleNavigation("/about")}
          className='flex flex-col items-center'
        >
          <img src={about} alt='About' className='w-5 h-5' />
          <BodySmall fontSize={12}>About</BodySmall>
        </MenuItem>
        {isLoggedIn ? (
          <Box>
            {ENABLE_MESSAGING_FEATURE && (
              <MenuItem
                onClick={() => handleNavigation("/dashboard/messaging")}
                className='flex flex-col items-center'
              >
                <img src={messaging} alt='Messaging' className='w-5 h-5' />
                <BodySmall fontSize={12}>Messaging</BodySmall>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => handleNavigation("/dashboard/notifications")}
              className='flex flex-col items-center'
            >
              <img src={notification} alt='Notifications' className='w-5 h-5' />
              <BodySmall fontSize={12}>Notifications</BodySmall>
              <NotificationsCard />
            </MenuItem>
            <MenuItem
              onClick={handleLoginLogout}
              className='flex flex-row items-center'
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                bgcolor={"primary.main"}
                color={"neutral.white"}
                className='px-2 py-1 rounded-full cursor-pointer duration-300 ease-in-out transition-transform'
              >
                <BodySmall fontSize={12}>Logout</BodySmall>
                <img
                  src={logoutSVG}
                  alt='logout'
                  className='w-2.5 h-2.5 ml-[3px]'
                />
              </Box>
            </MenuItem>
          </Box>
        ) : null}
      </Menu>
    </Box>
  );
};

export default MobileMenu;
