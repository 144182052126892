import React from "react";

import { TextButton } from "@components/base/Button";
import { BodyLarge, H6, Subtitle1 } from "@components/base/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  useMediaQuery,
  useTheme
} from "@mui/material";

interface PrivacyPolicyDialogProps {
  openPrivacyPolicy: boolean;
  handleClosePrivacyPolicy: () => void;
}

export default function PrivacyPolicyDialog({
  openPrivacyPolicy,
  handleClosePrivacyPolicy
}: PrivacyPolicyDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          width: fullScreen
            ? "100%"
            : { xs: "100%", sm: "90%", md: "70%", lg: "50%" },
          maxHeight: "90vh"
        }
      }}
      open={openPrivacyPolicy}
      onClose={handleClosePrivacyPolicy}
      scroll='paper'
    >
      <DialogTitle>Privacy Policy</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ overflowX: "hidden", wordBreak: "break-word" }}
        >
          <Subtitle1 gutterBottom>Last updated: April 12, 2024</Subtitle1>
          <BodyLarge paragraph>
            At LabShare, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, and safeguard the information
            you provide to us when using our platform.
          </BodyLarge>
          <H6>1. Information We Collect</H6>
          <ul>
            <li>
              <strong>Personal Information: </strong>When you register for an
              account on LabShare, we may collect personal information such as
              your name, email address, institution affiliation, and contact
              details.
            </li>
            <li>
              <strong>Research Data: </strong>In the course of using LabShare,
              you may upload and share research data, including but not limited
              to animal model information, tissue biospecimens, and related
              research materials.
            </li>
            <li>
              <strong>Usage Information: </strong>We may collect information
              about your interactions with the platform, including login times,
              pages visited, and actions taken.
            </li>
          </ul>
          <H6>2. How We Use Your Information</H6>
          <ul>
            <li>
              <strong>Providing Services: </strong> We use your personal
              information to operate and maintain LabShare, including
              facilitating resource sharing, managing user accounts, and
              communicating with you about platform updates and activities.
            </li>
            <li>
              <strong>Research Collaboration: </strong>Your research data and
              interactions with LabShare may be used to facilitate research
              collaboration and networking opportunities within the scientific
              community.
            </li>
            <li>
              <strong>Improving Our Services: </strong>We may analyze usage data
              to improve the functionality and user experience of LabShare, as
              well as to develop new features and services.
            </li>
          </ul>
          <H6>3. Information Sharing and Disclosure</H6>
          <ul>
            <li>
              <strong>Collaboration: </strong>LabShare is designed to facilitate
              collaboration and resource sharing among researchers. Your
              research data may be shared with other users of the platform for
              this purpose.
            </li>
            <li>
              <strong>Legal Compliance: </strong>We may disclose your
              information if required by law or in response to legal requests,
              such as court orders or subpoenas.
            </li>
            <li>
              <strong>Consent: </strong>We will obtain your consent before
              sharing your personal information with third parties for purposes
              other than those outlined in this Privacy Policy.
            </li>
          </ul>
          <H6>4. Data Security</H6>
          <ul>
            <li>
              We implement industry-standard security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction.
            </li>
            <li>
              However, please note that no method of transmission over the
              internet or electronic storage is 100% secure, and we cannot
              guarantee absolute security of your information.
            </li>
          </ul>
          <H6>5. Your Rights</H6>
          <ul>
            <li>
              You have the right to access, update, or delete your personal
              information at any time by logging into your LabShare account and
              editing your profile.
            </li>
            <li>
              You may also contact us to request assistance with accessing or
              modifying your information.
            </li>
          </ul>
          <H6>6. Changes to This Privacy Policy</H6>
          <ul>
            <li>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any significant changes by posting the updated Privacy Policy
              on our website or through other means of communication.
            </li>
          </ul>
          <BodyLarge paragraph>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at{" "}
            <Link href='mailto:info@labshareapp.com' underline='hover'>
              info@labshareapp.com
            </Link>
            .
          </BodyLarge>
          <BodyLarge paragraph>
            By using LabShare, you consent to the collection, use, and
            disclosure of your information as described in this Privacy Policy.
          </BodyLarge>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={handleClosePrivacyPolicy}>Close</TextButton>
      </DialogActions>
    </Dialog>
  );
}
