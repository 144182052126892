import axios from "axios";
import { enqueueSnackbar } from "notistack";

/**
 * Axios instance for making API requests within the application.
 *
 * This instance is pre-configured with the base URL and request interceptors.
 * It automatically adds the authorization token to request headers if available.
 */
const api = axios.create({
  baseURL: "/api",
  timeout: 10000
});

/**
 * Cancel token for canceling ongoing requests.
 *
 * This token source is used to create a CancelToken for each axios request.
 * It allows cancellation of requests using the AbortController signal.
 *
 * @type {CancelTokenSource}
 */
const cancelToken = axios.CancelToken;

// Intercept axios requests and add any pre-configuration we want
api.interceptors.request.use((options) => {
  // a variable to store the CancelToken for each request
  const cancelTokenSource = cancelToken.source();

  if (options?.signal && !options?.cancelToken) {
    const { signal } = options;

    options.cancelToken = cancelTokenSource.token;

    signal.addEventListener?.("abort", () => cancelTokenSource.cancel());
  }

  return options;
});

api.interceptors.response.use(null, (error) => {
  if (!error.response?.data) {
    return enqueueSnackbar(
      "Unable to connect to the server. Please try again later.",
      {
        variant: "warning"
      }
    );
  }

  const { errors, message } = error.response.data;

  // First check for validation errors array
  if (errors?.length > 0) {
    enqueueSnackbar(errors[0], {
      variant: "warning"
    });
    return Promise.reject(error);
  }

  // Then fallback to message
  if (message) {
    enqueueSnackbar(message, {
      variant: "warning"
    });
    return Promise.reject(error);
  }

  // Last resort fallback
  enqueueSnackbar("Something went wrong. Please try again later.", {
    variant: "warning"
  });

  return Promise.reject(error);
});

export { cancelToken };
export default api;
