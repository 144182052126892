import React, { FC, ReactNode } from "react";

import TSAppBar from "@components/AppBar/TSAppBar";
import Footer from "@components/Footer/Footer";

interface LayoutWithFooterProps {
  children: ReactNode;
}

const LayoutWithFooter: FC<LayoutWithFooterProps> = ({ children }) => (
  <>
    <TSAppBar />

    <div className='flex flex-col min-h-screen'>
      <main className='flex-1'>{children}</main>
      <Footer />
    </div>
  </>
);

export default LayoutWithFooter;
