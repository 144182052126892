import React, { FC, ReactNode } from "react";

import { Subtitle1, Subtitle2 } from "@components/base/Typography";
import { Transition } from "@headlessui/react";
import User from "@interfaces/user";
import { Box, Stack } from "@mui/material";

interface DashboardSidebarMobileProps {
  children: ReactNode;
  disableUserCard?: boolean;
  disableSecondaryNavigation?: boolean;
  //eslint-disable-next-line
  loading: any;
  currentUser?: User;
  showSidebar?: boolean;
}

const DashboardSidebarMobile: FC<DashboardSidebarMobileProps> = ({
  children,
  currentUser,
  loading,
  showSidebar
}) => {
  const institutionName = currentUser?.institutionName || "";

  return (
    <div className='md:hidden'>
      <Transition
        show={showSidebar as boolean}
        className='fixed inset-0 mt-[57px] sm:mt-[65px] flex z-40'
      >
        <Transition.Child
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          className='fixed inset-0'
        >
          <div className='absolute inset-0 bg-gray-600 opacity-75' />
        </Transition.Child>
        <Transition.Child
          enter='transition ease-in-out duration-300 transform'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'
          className='relative flex-1 flex flex-col w-full'
        >
          <div className='shrink-0 flex border-b border-gray-300 items-start w-72  sm:border-r sm:border-r-gray-100 bg-white'>
            {/* We will show the institution's logo here */}
            <Stack className=' w-[90%] pt-6 mr-2 items-center justify-center '>
              <Box
                component='div'
                sx={{
                  display: {
                    xs: "block",
                    md: "none"
                  },
                  textAlign: "center",
                  width: "100%",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                <Subtitle1
                  fontFamily={'"Shippori-Mincho-B1", serif'}
                  textTransform={"uppercase"}
                  fontSize={{ base: "14px", md: "22px", lg: "32px" }}
                  fontWeight={700}
                  textAlign={"center"}
                >
                  {loading.main ? "Loading... University" : institutionName}
                </Subtitle1>
              </Box>
              <Box mb={2} mt={2} px={2}>
                <Subtitle2
                  fontFamily={'"Shippori-Mincho-B1", serif'}
                  textTransform={"uppercase"}
                  fontSize={{ base: "8px", md: "10px", lg: "16px" }}
                  fontWeight={400}
                  textAlign={"center"}
                >
                  RESOURCE SHARING PORTAL
                </Subtitle2>
              </Box>
            </Stack>
          </div>
          <div className='flex-1 h-0 pt-3 pb-3 overflow-y-auto w-72 sm:border-r sm:border-r-gray-100 bg-white'>
            {children}
            <div className='mx-6 my-3 border-b border-gray-100' />
          </div>
        </Transition.Child>
      </Transition>
    </div>
  );
};

export default DashboardSidebarMobile;
