import React from "react";

import {
  Box,
  CardContent,
  Card as MuiCard,
  CardProps as MuiCardProps,
  styled,
  Typography
} from "@mui/material";

/**
 * @interface BaseCardProps
 * @extends {MuiCardProps}
 */
interface BaseCardProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
  contentProps?: React.ComponentProps<typeof CardContent>;
  variant?: "elevation" | "outlined";
  sx?: MuiCardProps["sx"];
  className?: string;
}

// Base styled card with common styles
const BaseCard = styled(MuiCard)(() => ({
  borderRadius: "10px"
}));

/**
 * @component Card
 * @description Standard card component with optional title and subtitle
 */
export const Card = React.forwardRef<
  HTMLDivElement,
  BaseCardProps & MuiCardProps
>(({ title, subtitle, action, children, contentProps, ...props }, ref) => (
  <BaseCard ref={ref} {...props}>
    <CardContent
      {...contentProps}
      sx={{
        px: 4,
        ":first-child": { paddingTop: "16px" },
        "&:last-child": { paddingBottom: "16px" },
        ...contentProps?.sx
      }}
    >
      {title && action && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography
            variant='h6'
            fontWeight='bold'
            fontSize='1.2rem'
            gutterBottom
          >
            {title}
          </Typography>

          {action}
        </div>
      )}
      {title && !action && (
        <Typography
          variant='h6'
          fontWeight='bold'
          fontSize='1.2rem'
          gutterBottom
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          color='text.secondary'
          gutterBottom
          sx={{ marginTop: title ? -1 : 0 }}
        >
          {subtitle}
        </Typography>
      )}
      <Box sx={{ marginTop: title || subtitle ? 1 : 0 }}>{children}</Box>
    </CardContent>
  </BaseCard>
));
Card.displayName = "Card";

/**
 * @component MetricCard
 * @description Specialized card for metrics display
 */
export const MetricCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-2px)",
    "& .icon": {
      opacity: 0
    },
    "& .hover-icon": {
      opacity: 1
    }
  },
  "& .icon, & .hover-icon": {
    transition: theme.transitions.create("opacity")
  },
  "& .hover-icon": {
    opacity: 0
  }
}));

/**
 * @component OutlinedCard
 * @description Card with outlined variant
 */
export const OutlinedCard = React.forwardRef<
  HTMLDivElement,
  BaseCardProps & MuiCardProps
>((props, ref) => <Card ref={ref} variant='outlined' {...props} />);
OutlinedCard.displayName = "OutlinedCard";
