import React from "react";

import { PrimaryButton } from "@components/base/Button";
import { Card } from "@components/base/Card";
import { BodyLarge, H3 } from "@components/base/Typography";
import { Box } from "@mui/material";
import backgroundImg from "@static/instDatabase.png";

interface PendingApprovalPageProps {
  showSidebar: boolean;
}

export default function PendingApprovalPage({
  showSidebar
}: PendingApprovalPageProps) {
  const handleContactSupport = () => {
    window.location.href = "mailto:info@labshareapp.com";
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        overflow: "hidden"
      }}
    >
      <img
        src={backgroundImg}
        alt='Background'
        className='w-4/5 h-full object-cover filter blur-[8px]'
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: showSidebar ? 0 : { xs: "42%", md: "50%" },
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          transform: showSidebar ? "translateX(-150px)" : "translateX(-50%)",
          transition: "left 0.5s ease, transform 0.5s ease"
        }}
      >
        <Card
          sx={{
            maxWidth: { xs: 250, sm: 400 }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3
            }}
          >
            <H3 align='center' sx={{ fontWeight: "bold" }}>
              Account Pending Approval
            </H3>
            <BodyLarge align='center'>
              Your account is pending approval from your institution
              administrator. Please try again later or contact support if your
              request has not been approved within 48 hours.
            </BodyLarge>
            <PrimaryButton
              size='large'
              fullWidth
              onClick={handleContactSupport}
              sx={{
                mt: 1,
                py: 1.5,
                maxWidth: 300
              }}
            >
              Contact Support
            </PrimaryButton>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
