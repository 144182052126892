import React from "react";

import { SecondaryButton } from "@components/base/Button";
import { OutlinedCard } from "@components/base/Card";
import { Add } from "@mui/icons-material";

interface DatabaseHeaderProps {
  onSuggestBioBank: () => void;
}

const DatabaseHeader: React.FC<DatabaseHeaderProps> = ({
  onSuggestBioBank
}) => {
  return (
    <OutlinedCard
      sx={{
        "&:hover": { boxShadow: "none" },
        marginBottom: 5
      }}
      title={"Consolidated Biorepository & Resource Database"}
      subtitle={
        "Search for publicly available resources (animal models, celllines, biospecimens) from around the world"
      }
    >
      <SecondaryButton
        color='primary'
        startIcon={<Add />}
        onClick={onSuggestBioBank}
      >
        Suggest BioBank
      </SecondaryButton>
    </OutlinedCard>
  );
};

export default DatabaseHeader;
