import React, { createContext, lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// eslint-disable-next-line import/order
import * as amplitude from "@amplitude/analytics-browser";

import "./styles/index.css";

import TSAppBar from "@components/AppBar/TSAppBar";
import ProtectedInstitutionalRoute from "@components/ProtectedRoute/ProtectedInstitutionalRoute";
import RedirectProtected from "@components/ProtectedRoute/RedirectProtected";
import ScrollToHashElement from "@components/ScrollToHashElement/ScrollToHashElement";
import { RedirectProvider } from "@contexts/RedirectContext";
import ExternalDatabasePage from "@features/ExternalDatabase/ExternalDatabasePage";
import MessagingPage from "@features/Messaging/MessagingPage";
import {
  Box,
  CircularProgress,
  CssBaseline,
  styled,
  ThemeProvider
} from "@mui/material";
import Portal from "@pages/InstitutionalPortal/Portal";
import { MaterialDesignContent, SnackbarProvider } from "notistack";

import { REACT_APP_AMPLITUDE_API_KEY, REACT_APP_SOCKET_URL } from "./config";
import { getConfigs } from "./config/configSlice";
import { DashboardRoute } from "./layout/dashboard";
import LayoutWithFooter from "./layout/LayoutWithFooter";
import { AppDispatch } from "./services/store";
import { theme } from "./styles/theme";

const TRACKING_ID = "G-JFRMYH6DYX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#fafafa",
    color: "#146981",
    overflowX: "hidden",
    wordBreak: "break-all"
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: "#fafafa",
    color: "#811414",
    overflowX: "hidden",
    wordBreak: "break-all"
  }
}));

createContext({});

const CreatePage = lazy(() => import("@features/Create/CreatePage"));
const PostPage = lazy(() => import("@features/Posts/PostPage"));
const ProfilePage = lazy(() => import("@features/Profile/ProfilePage"));
const AboutPage = lazy(() => import("@pages/About/AboutPage"));
const EmailVerificationPage = lazy(
  () => import("@pages/EmailVerificationPage")
);
const FAQPage = lazy(() => import("@pages/FAQs/FaqPage"));
const ForgotPasswordPage = lazy(() => import("@pages/ForgotPasswordPage"));
const GetStartedPage = lazy(() => import("@pages/get-started/GetStartedPage"));
const LandingPage = lazy(() => import("@pages/Landing/LandingPage"));
const LoginPage = lazy(() => import("@pages/LoginPage"));
const NotFound = lazy(() => import("@pages/NotFound"));
const RegisterPage = lazy(() => import("@pages/RegisterPage"));
const ResetPasswordPage = lazy(() => import("@pages/ResetPasswordPage"));
const DonatePage = lazy(() => import("@features/Donate/DonatePage"));
const LabPage = lazy(() => import("@pages/InstitutionalPortal/Lab/LabPage"));
const HomePage = lazy(() => import("@features/Home/Home"));
const SearchPage = lazy(() => import("@pages/SearchPage/SearchResultsPage"));
const TransferPage = lazy(() => import("@features/Transfer/TransferPage"));
const InstitutionMetricsPage = lazy(
  () => import("@features/Metrics/InstitutionMetricsPage")
);
const InstitutionSearchPage = lazy(
  () =>
    import("@pages/InstitutionalPortal/LabAndPeopleSearch/LabAndPeopleSearch")
);
const InstitutionalDatabase = lazy(
  () =>
    import(
      "@pages/InstitutionalPortal/InstitutionalDatabase/InstitutionalDatabase"
    )
);
const SettingsPage = lazy(() => import("@pages/Settings/SettingsPage"));
const NotAuthorizedView = lazy(() => import("@pages/NotAuthorizedView"));
const NotificationsPage = lazy(
  () => import("@pages/Notifications/NotificationsPage")
);
const InstitutionUsersPage = lazy(
  () => import("@pages/InstitutionalPortal/Admin/InstitutionUsersPage")
);
const SetPasswordPage = lazy(() => import("@pages/SetPasswordPage"));
function App() {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const pageRoute = window.location.pathname + window.location.search;
    const pageTitle =
      pageRoute.length > 1
        ? pageRoute[1].toUpperCase() + pageRoute.slice(2)
        : pageRoute;
    ReactGA.send({
      hitType: "pageview",
      page: pageRoute,
      title: pageTitle
    });

    dispatch(getConfigs());
  }, []);

  useEffect(() => {
    const amplitudeKey = REACT_APP_AMPLITUDE_API_KEY || "";
    if (amplitudeKey) {
      amplitude.init(amplitudeKey, { autocapture: true });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          httpEquiv='Content-Security-Policy'
          content={`default-src 'self';
            style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
            script-src 'self';
            script-src-elem 'self' https://*.googletagmanager.com/ https://*.youtube.com/ https://*.google-analytics.com/ https://api2.amplitude.com https://*.amplitude.com;
            connect-src 'self' ws: wss: ${REACT_APP_SOCKET_URL} https://*.google-analytics.com/ https://api2.amplitude.com https://*.amplitude.com;
            frame-src 'self' https://*.youtube.com/;
            img-src 'self' data: blob: https://*.s3.amazonaws.com/ https://*.google-analytics.com/;`}
        />
      </Helmet>
      <RedirectProvider>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              preventDuplicate
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              Components={{
                success: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent
              }}
            >
              <BrowserRouter>
                <Suspense
                  fallback={
                    <Box
                      mt={10}
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      height='200px'
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Box
                    sx={{
                      // due to responsive height of appbar
                      marginTop: {
                        xs: "56px",
                        sm: "64px"
                      }
                    }}
                  >
                    <ScrollToHashElement />
                    <Routes>
                      <Route
                        path='/login'
                        element={
                          <RedirectProtected
                            requiresGetStarted
                            element={
                              <LayoutWithFooter>
                                <LoginPage />
                              </LayoutWithFooter>
                            }
                          />
                        }
                      />
                      <Route
                        path='/limited-access'
                        element={<NotAuthorizedView />}
                      />
                      <Route
                        path='/forgot-password'
                        element={
                          <LayoutWithFooter>
                            <ForgotPasswordPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/set-password'
                        element={
                          <LayoutWithFooter>
                            <SetPasswordPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/reset-password'
                        element={
                          <LayoutWithFooter>
                            <ResetPasswordPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/register'
                        element={
                          <RedirectProtected
                            requiresGetStarted
                            element={
                              <LayoutWithFooter>
                                <RegisterPage />
                              </LayoutWithFooter>
                            }
                          />
                        }
                      />
                      <Route
                        path='/get-started'
                        element={
                          <LayoutWithFooter>
                            <GetStartedPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/verify'
                        element={
                          <LayoutWithFooter>
                            <EmailVerificationPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/about'
                        element={
                          <LayoutWithFooter>
                            <AboutPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/faqs'
                        element={
                          <LayoutWithFooter>
                            <FAQPage />
                          </LayoutWithFooter>
                        }
                      />
                      <Route
                        path='/'
                        element={
                          <>
                            <TSAppBar />
                            <LandingPage />
                          </>
                        }
                      />
                      <Route
                        path='/dashboard/*'
                        Component={(props: Record<string, unknown>) => (
                          <>
                            <DashboardRoute
                              component={Portal}
                              redirectUnauthorized
                              {...props}
                            />
                          </>
                        )}
                      >
                        <Route path='home' element={<HomePage />} />
                        <Route path='search' element={<SearchPage />} />
                        <Route path='create' element={<CreatePage />} />
                        <Route
                          path='member/:userId'
                          element={<ProfilePage />}
                        />
                        <Route
                          path='lab'
                          element={
                            <ProtectedInstitutionalRoute component={LabPage} />
                          }
                        />
                        <Route
                          path='institution-database'
                          element={
                            <ProtectedInstitutionalRoute
                              component={InstitutionalDatabase}
                            />
                          }
                        />
                        <Route
                          path='external-database'
                          element={<ExternalDatabasePage />}
                        />
                        <Route
                          path='institution-search'
                          element={
                            <ProtectedInstitutionalRoute
                              component={InstitutionSearchPage}
                            />
                          }
                        />
                        <Route
                          path='transfer'
                          element={
                            <ProtectedInstitutionalRoute
                              component={TransferPage}
                            />
                          }
                        />
                        <Route
                          path='donate'
                          element={
                            <ProtectedInstitutionalRoute
                              component={DonatePage}
                            />
                          }
                        />
                        <Route
                          path='metrics'
                          element={<InstitutionMetricsPage />}
                        />
                        <Route path='posts/:postId' element={<PostPage />} />
                        <Route
                          path='messaging/:room_id?'
                          element={<MessagingPage />}
                        />
                        <Route path='*' element={<NotFound dashboard />} />
                        <Route path='posts/:postId' element={<PostPage />} />
                        <Route path='settings' element={<SettingsPage />} />
                        <Route
                          path='notifications'
                          element={<NotificationsPage />}
                        />
                        <Route
                          path='institution-users'
                          element={<InstitutionUsersPage />}
                        />
                      </Route>
                      <Route
                        path='/*'
                        element={
                          <LayoutWithFooter>
                            <TSAppBar />
                            <NotFound />
                          </LayoutWithFooter>
                        }
                      />
                    </Routes>
                  </Box>
                </Suspense>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </React.StrictMode>
      </RedirectProvider>
    </>
  );
}

export default App;
