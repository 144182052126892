import React from "react";

import { RequestButton, ShareButton } from "@components/base/Button";
import { BodyLarge, BodySmall } from "@components/base/Typography";
import { TransferSourceFieldValues } from "@interfaces/transfer";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";

interface TransferSourceFieldsProps {
  formik: FormikProps<TransferSourceFieldValues>;
}
export const TransferSourceFields: React.FC<TransferSourceFieldsProps> = ({
  formik
}) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"left"}
        gap={2}
      >
        <BodyLarge textAlign='left' mb={0}>
          I am<sup className='text-red-500 font-bold'>*</sup>
        </BodyLarge>
        <ShareButton
          sx={{
            borderRadius: "50px",
            ...(formik.errors.source ? { borderColor: "border.resource" } : {})
          }}
          variant={
            formik.values.source === "donation" ? "contained" : "outlined"
          }
          onClick={() => formik.setFieldValue("source", "donation")}
        >
          Donating
        </ShareButton>
        <RequestButton
          sx={{
            borderRadius: "50px",
            ...(formik.errors.source ? { borderColor: "border.resource" } : {})
          }}
          variant={
            formik.values.source === "receipt" ? "contained" : "outlined"
          }
          onClick={() => formik.setFieldValue("source", "receipt")}
        >
          Receiving
        </RequestButton>
      </Stack>
      {formik.errors.source && (
        <BodySmall
          ml={"14px"}
          mt={"3px"}
          lineHeight={1.66}
          fontSize={"0.75rem"}
          color='error'
        >
          {formik.errors.source}
        </BodySmall>
      )}
    </>
  );
};
