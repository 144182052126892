import React from "react";

import { PrimaryButton } from "@components/base/Button";
import { Input } from "@components/base/Input";
import { H2 } from "@components/base/Typography";
import { Box, Modal, Stack } from "@mui/material";
import { useFormik } from "formik";

interface SuggestBiobankModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: {
    biobank_name: string;
    biobank_resource_link: string;
  }) => Promise<void>;
}

const SuggestBiobanModal: React.FC<SuggestBiobankModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues: {
      biobank_name: "",
      biobank_resource_link: ""
    },
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values);
      resetForm();
      onClose();
    }
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4
        }}
      >
        <H2
          id='modal-title'
          variant='h6'
          component='h2'
          sx={{ marginBottom: "16px", fontWeight: "bold" }}
        >
          Suggest a BioBank
        </H2>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <Input
              label='Biobank Name'
              fullWidth
              name='biobank_name'
              value={formik.values.biobank_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.biobank_name &&
                Boolean(formik.errors.biobank_name)
              }
              helperText={
                formik.touched.biobank_name
                  ? formik.errors.biobank_name || ""
                  : ""
              }
            />

            <Input
              label='Biobank Resource Link'
              fullWidth
              type='url'
              name='biobank_resource_link'
              value={formik.values.biobank_resource_link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.biobank_resource_link &&
                Boolean(formik.errors.biobank_resource_link)
              }
              helperText={
                formik.touched.biobank_resource_link
                  ? formik.errors.biobank_resource_link || ""
                  : ""
              }
            />

            <PrimaryButton type='submit'>Submit</PrimaryButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default SuggestBiobanModal;
