import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BodyLarge, BodySmall } from "@components/base/Typography";
import { getUser } from "@features/Auth/authSlice";
import {
  getParticipantRooms,
  getRoomMessages,
  markMessagesAsRead,
  selectParticipantRooms,
  selectRoomMessages
} from "@features/Messaging/messagingSlice";
import { socketJoinRoom } from "@features/sockets/socket-events";
import { joinRoom } from "@features/sockets/socketSlice";
import { BASE_S3_URL } from "@features/utils/manage-file";
import { Room } from "@interfaces/messaging";
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import DefaultProfile from "@static/icons/default-profile.png";
import { RootState } from "src/services/store";
import { AppDispatch } from "src/services/store";
interface ListProps {
  setShowMessageList: (newData: boolean) => void;
}

// eslint-disable-next-line react/display-name
const MessageList: React.FC<ListProps> = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ setShowMessageList }) => {
    const dispatch = useDispatch<AppDispatch>();
    const participantRooms = useSelector(selectParticipantRooms);
    const roomMessages = useSelector(selectRoomMessages);
    const currentUser = useSelector(getUser);
    const newMessages = useSelector(
      (state: RootState) => state.socket.messages
    );

    const [availableRooms, setAvailableRooms] = useState<Room[]>([]);

    const handleAvailableRoomClick = (room_id: string) => {
      dispatch(
        markMessagesAsRead({ readerId: currentUser.id, roomId: room_id })
      );

      dispatch(socketJoinRoom(room_id));

      dispatch(joinRoom(room_id));

      dispatch(getRoomMessages({ roomId: room_id }));
    };

    useEffect(() => {
      if (currentUser?.id) {
        dispatch(getParticipantRooms({ participantId: currentUser.id }));
      }
    }, [dispatch, currentUser, newMessages, roomMessages]);

    useEffect(() => {
      if (participantRooms && participantRooms.length > 0) {
        setAvailableRooms(participantRooms);
      }
    }, [participantRooms, roomMessages, newMessages]);

    const memoizedRoomList = useMemo(
      () =>
        availableRooms?.map((room, index) => (
          <ListItem
            key={index}
            sx={{ width: { xs: "auto", sm: "100%" } }}
            onClick={() => {
              handleAvailableRoomClick(room.id);
              setShowMessageList(false);
            }}
            button
          >
            <ListItemAvatar>
              {room?.creator_id === currentUser.id ? (
                <Avatar
                  src={
                    !room?.participant?.profile_photo_thumbnail_key ||
                    room?.participant?.profile_photo_thumbnail_key.length === 0
                      ? DefaultProfile
                      : `${BASE_S3_URL}${room.participant.profile_photo_thumbnail_key}`
                  }
                />
              ) : (
                <Avatar
                  src={
                    !room?.creator?.profile_photo_thumbnail_key ||
                    room?.creator?.profile_photo_thumbnail_key.length === 0
                      ? DefaultProfile
                      : `${BASE_S3_URL}${room.creator.profile_photo_thumbnail_key}`
                  }
                />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {room?.creator_id === currentUser.id ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BodyLarge sx={{ marginRight: "10px" }}>
                        {`${room?.participant.first_name} ${room?.participant.last_name}`}
                      </BodyLarge>
                      {room?.unread_count > 0 && (
                        <Badge
                          badgeContent={room?.unread_count}
                          color='error'
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "primary.main",
                              color: "neutral.white",
                              fontSize: "0.65rem",
                              minWidth: "16px",
                              height: "16px",
                              padding: "0 4px"
                            }
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BodyLarge
                        sx={{ marginRight: "20px", textOverflow: "ellipsis" }}
                      >
                        {`${room?.creator.first_name} ${room?.creator.last_name}`}
                      </BodyLarge>
                      {room?.unread_count > 0 && (
                        <Badge
                          badgeContent={room?.unread_count}
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "primary.main",
                              color: "neutral.white",
                              fontSize: "0.65rem",
                              minWidth: "16px",
                              height: "16px",
                              padding: "0 4px"
                            }
                          }}
                        />
                      )}
                    </Box>
                  )}
                  <BodySmall
                    color='textSecondary'
                    sx={{
                      fontSize: "0.75rem",
                      marginRight: "20px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "50px"
                    }}
                  >
                    {room?.last_message?.created_at &&
                      new Date(room.last_message.created_at).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true
                        }
                      )}
                  </BodySmall>
                </Box>
              }
              secondary={`${room?.last_message?.sender_id === currentUser?.id ? "You" : room?.last_message?.sender?.first_name}: ${room?.last_message?.content}`}
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px"
                }
              }}
            />
          </ListItem>
        )),
      [availableRooms, currentUser.id, handleAvailableRoomClick]
    );

    return (
      <List
        sx={{
          flexDirection: "row",
          overflowX: "auto",
          whiteSpace: "nowrap",
          padding: "none"
        }}
      >
        {memoizedRoomList.length > 0 ? (
          memoizedRoomList
        ) : (
          <BodyLarge sx={{ paddingLeft: "16px" }}>
            No recent conversations
          </BodyLarge>
        )}
      </List>
    );
  }
);
export default MessageList;
