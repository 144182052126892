import imageCompression from "browser-image-compression";
import { S3_BUCKET_NAME } from "src/config";
import api, { cancelToken } from "src/services/api";

import getError from "./get-error";

export const KEY_NAME_EDITED = "edited.webp";
export const KEY_NAME_ORIGINAL = "original.webp";
export const KEY_NAME_THUMBNAIL = "thumbnail.webp";
export const DIMENSION_EDITED = "edited";
export const DIMENSION_THUMBNAIL = "thumbnail";
export const BASE_S3_URL = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/`;
export const MAX_FILE_SIZE_KB = 350;

function getFileExtension(filename: string): string {
  return filename
    ?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
}
function objectKeyThumbnail(currentUserId: string, KEY_NAME: string): string {
  return `${currentUserId}/${KEY_NAME}`;
}

async function s3DownloadImg(objectKey: string, userId: string) {
  const source = cancelToken.source();
  const timeoutDuration = 20000;

  try {
    const response = await api.get(`/media/profile-photo-download/${userId}`, {
      cancelToken: source.token,
      headers: {
        "Content-Type": "applcation/json"
      },
      responseType: "blob",
      timeout: timeoutDuration,
      data: { key: objectKey }
    });
    return await response.data;
  } catch (error) {
    return getError(error);
  }
}
async function compressFile(file: File): Promise<File> {
  let compressedFile = await imageCompression(file, {
    maxSizeMB: MAX_FILE_SIZE_KB / 1024
  });

  if (compressedFile.size > MAX_FILE_SIZE_KB * 1024) {
    const options = {
      maxSizeMB: MAX_FILE_SIZE_KB / 1024,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    compressedFile = await imageCompression(compressedFile, options);
  }

  return compressedFile;
}

export {
  s3DownloadImg,
  getFileExtension,
  objectKeyThumbnail,
  imageCompression,
  compressFile
};
