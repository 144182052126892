import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BodyLarge } from "@components/base/Typography";
import { logout } from "@features/Auth/authSlice";
import { BASE_S3_URL } from "@features/utils/manage-file";
import User from "@interfaces/user";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import cog from "@static/icons/cog.svg";
import DefaultProfile from "@static/icons/default-profile.png";
import logoutSVG from "@static/icons/logout-dark.svg";
import thumbsUp from "@static/icons/thumbs-up.svg";
import { AppDispatch } from "src/services/store";

interface UserBlockProps {
  currentUser: User;
}

const UserBlock: React.FC<UserBlockProps> = ({ currentUser = {} }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openActionMenu = Boolean(anchorEl);

  const handleClickActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const imgUrl =
    !currentUser?.profilePhotoThumbnailKey ||
    currentUser.profilePhotoThumbnailKey.length === 0
      ? DefaultProfile
      : `${BASE_S3_URL}${currentUser.profilePhotoThumbnailKey}`;

  const handleLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    handleCloseActionMenu();

    const logoutResponse = await dispatch(logout());
    if (logoutResponse.payload.logoutUrl) {
      return (window.location.href = logoutResponse.payload.logoutUrl);
    }

    navigate("/");
  };

  const handleSettingsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleCloseActionMenu();
    // Use setTimeout to ensure menu closes before navigation
    setTimeout(() => navigate("/dashboard/settings"), 0);
  };

  const handleFeedbackClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleCloseActionMenu();
    // Use setTimeout to ensure menu closes before opening new window
    setTimeout(
      () => window.open("https://forms.gle/Lz1R9RTdys8upTjF8", "_blank"),
      0
    );
  };

  return (
    <Box px={3} mb={3} width={{ xs: "60%", sm: "80%" }}>
      <Stack direction={"row"} gap={1} px={1} justifyContent={"space-between"}>
        <Box
          position={"relative"}
          display='inline-block'
          height={"min-content"}
        >
          <Avatar sx={{ width: 55, height: 55 }} src={imgUrl} />
          <Box
            position='absolute'
            bottom={0}
            right={0}
            width={15}
            height={15}
            bgcolor='green'
            borderRadius='50%'
            border='2px solid'
            borderColor={"border.white"}
          />
        </Box>

        <Stack>
          <Stack gap={0.1} sx={{ maxWidth: "120px" }}>
            <BodyLarge
              fontWeight={700}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {currentUser.firstName} {currentUser.lastName}
            </BodyLarge>
            <BodyLarge
              mb={1}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {currentUser.email}
            </BodyLarge>
          </Stack>
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          <IconButton
            aria-label='settings'
            onClick={handleClickActionMenu}
            aria-expanded={openActionMenu ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={openActionMenu}
          onClose={handleCloseActionMenu}
        >
          {/* Settings */}
          <MenuItem onClick={handleSettingsClick} sx={{ width: "100%" }}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{ width: "100%" }}
            >
              <img src={cog} alt='settings' className='w-5 h-5' />
              <BodyLarge>Settings</BodyLarge>
            </Stack>
          </MenuItem>

          {/* Feedback */}
          <MenuItem onClick={handleFeedbackClick} sx={{ width: "100%" }}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{ width: "100%" }}
            >
              <img src={thumbsUp} alt='feedback' className='w-5 h-5' />
              <BodyLarge>Give Feedback</BodyLarge>
            </Stack>
          </MenuItem>

          <MenuItem onClick={handleLogout} sx={{ width: "100%" }}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <img src={logoutSVG} alt='logout' className='h-5' />
              <BodyLarge>Logout</BodyLarge>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
    </Box>
  );
};

export default UserBlock;
