import React from "react";

import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  styled,
  TextField
} from "@mui/material";

/**
 * @interface BaseInputProps
 * @extends {StandardTextFieldProps}
 * @description Base props interface for all input components
 */
interface BaseInputProps extends StandardTextFieldProps {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onEndIconClick?: () => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
}

// Base styled input with common styles
const BaseInput = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "& fieldset": {
      borderColor: theme.palette.border.main
    },
    "&:hover fieldset": {
      borderColor: theme.palette.border.dark
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main
    },
    "&.Mui-error fieldset": {
      borderColor: theme.palette.error.main
    }
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: theme.palette.primary.main
    },
    "&.Mui-error": {
      color: theme.palette.error.main
    }
  }
}));

/**
 * @component Input
 * @description Standard text input component with optional icons
 */
export const Input = React.forwardRef<HTMLDivElement, BaseInputProps>(
  (
    { startIcon, endIcon, onEndIconClick, error, helperText, ...props },
    ref
  ) => (
    <BaseInput
      ref={ref}
      error={error}
      helperText={helperText}
      InputProps={{
        startAdornment: startIcon && (
          <InputAdornment position='start'>{startIcon}</InputAdornment>
        ),
        endAdornment: endIcon && (
          <InputAdornment position='end'>
            {onEndIconClick ? (
              <IconButton onClick={onEndIconClick} edge='end'>
                {endIcon}
              </IconButton>
            ) : (
              endIcon
            )}
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
);
Input.displayName = "Input";

/**
 * @component SearchInput
 * @description Specialized input for search functionality
 */
export const SearchInput = React.forwardRef<HTMLDivElement, BaseInputProps>(
  (props, ref) => (
    <Input ref={ref} placeholder='Search...' size='small' {...props} />
  )
);
SearchInput.displayName = "SearchInput";
