import React from "react";

import { BodyLarge } from "@components/base/Typography";

export const highlightText = (
  text: string,
  searchTerm: string
): React.JSX.Element[] => {
  if (!searchTerm) return [<span key='plain-text'> {text} </span>];

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
  let firstMatchHighlighted = false;

  return parts.map((part, index) => {
    const isMatch =
      part.toLowerCase() === searchTerm.toLowerCase() && !firstMatchHighlighted;
    if (isMatch) {
      firstMatchHighlighted = true;
    }
    return (
      <BodyLarge
        key={index}
        component='span'
        fontWeight={isMatch ? "bold" : "normal"}
        {...(isMatch ? { sx: { margin: "0 2px" } } : {})}
      >
        {part}
      </BodyLarge>
    );
  });
};
