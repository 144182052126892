import React from "react";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { Input } from "@components/base/Input";
import { BodyLarge } from "@components/base/Typography";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Autocomplete, CircularProgress, Stack } from "@mui/material";
import { FormikProps } from "formik";
import { autoCompleteStyles } from "src/styles/main";

interface AntiBodyFieldsProps {
  isMobile: boolean;
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
}

const AntiBodyFields: React.FC<AntiBodyFieldsProps> = ({
  loading,
  resourcePropsNames,
  setResourcePropsValues,
  resourcePropsValues,
  formik,
  isMobile
}) => {
  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id={"name"}
        resourceNames={resourcePropsNames?.antibody || []}
        label={"Name of Antibody"}
        loading={loading}
        value={formik.values.name || ""}
        requiredField
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });
          formik.setFieldValue("name", newValue?.toString() || "");
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ""}
      />
      <ResourceAutocomplete
        id={"add_gene_external_id"}
        resourceNames={resourcePropsNames?.add_gene_external_id || []}
        label={"AddGene ID (if known)"}
        value={formik.values.add_gene_external_id || ""}
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            add_gene_external_id: newValue
          });
          formik.setFieldValue(
            "add_gene_external_id",
            newValue?.toString() || ""
          );
        }}
        error={
          formik.touched.add_gene_external_id &&
          Boolean(formik.errors.add_gene_external_id)
        }
        helperText={
          (formik.touched.add_gene_external_id &&
            formik.errors.add_gene_external_id) ||
          ""
        }
      />
      <Stack direction={isMobile ? "column" : "row"} spacing={3}>
        <ResourceAutocomplete
          id={"species_name"}
          fullWidth
          resourceNames={resourcePropsNames?.species_name || []}
          value={formik.values.species_name || ""}
          label={"Species Name"}
          loading={loading}
          requiredField
          optionsFilter={optionsFilter}
          onChange={(_, newValue) => {
            setResourcePropsValues({
              ...resourcePropsValues,
              species_name: newValue
            });
            formik.setFieldValue("species_name", newValue?.toString() || "");
          }}
          error={
            formik.touched.species_name && Boolean(formik.errors.species_name)
          }
          helperText={
            (formik.touched.species_name && formik.errors.species_name) || ""
          }
        />

        <Autocomplete
          id={"isotype"}
          fullWidth
          multiple
          options={[]}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          ChipProps={{ color: "primary", variant: "outlined" }}
          filterOptions={optionsFilter}
          getOptionLabel={(option) => option}
          onChange={(_, newValue) => {
            setResourcePropsValues({
              ...resourcePropsValues,
              synonyms: newValue
            });
            formik.setFieldValue("isotype", (newValue || "").toString());
          }}
          renderInput={(params) => (
            <Input
              {...params}
              label={
                <BodyLarge>
                  ISO Type
                  <sup className='text-red-500 font-bold'>*</sup>
                </BodyLarge>
              }
              error={formik.touched.isotype && Boolean(formik.errors.isotype)}
              helperText={
                (formik.touched.isotype && formik.errors.isotype) || ""
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} className={autoCompleteStyles}>
              <>Add &quot;{option}&quot;</>
            </li>
          )}
        />
      </Stack>
      <ResourceAutocomplete
        id={"target_gene_names"}
        fullWidth
        multiple
        resourceNames={resourcePropsNames?.target_gene_names || []}
        label={"Target Gene(s)"}
        loading={loading}
        value={formik.values?.target_gene_names || []}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          const updatedValue = Array.isArray(newValue) ? newValue : [newValue];
          setResourcePropsValues({
            ...resourcePropsValues,
            target_gene_names: updatedValue
          });
          formik.setFieldValue("target_gene_names", updatedValue);
        }}
        error={
          formik.touched.target_gene_names &&
          Boolean(formik.errors.target_gene_names)
        }
        helperText={
          Array.isArray(formik.errors.target_gene_names)
            ? formik.errors.target_gene_names.join(", ")
            : formik.errors.target_gene_names || ""
        }
      />
      <ResourceAutocomplete
        id='storage_condition_names'
        multiple
        resourceNames={resourcePropsNames?.storage_condition_names || []}
        label={"Storage Condition"}
        loading={loading}
        value={formik.values.storage_condition_names || []}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          const updatedValue = Array.isArray(newValue) ? newValue : [newValue];
          setResourcePropsValues({
            ...resourcePropsValues,
            storage_condition_names: updatedValue
          });
          formik.setFieldValue("storage_condition_names", updatedValue);
        }}
        error={
          formik.touched.storage_condition_names &&
          Boolean(formik.errors.storage_condition_names)
        }
        helperText={
          (formik.touched.storage_condition_names &&
            formik.errors.storage_condition_names) ||
          ""
        }
      />
    </Stack>
  );
};

export default AntiBodyFields;
