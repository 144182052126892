import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import NotificationsCard from "@components/AppBar/NotificationsCard";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton
} from "@components/base/Button";
import { BodySmall } from "@components/base/Typography";
import { logout } from "@features/Auth/authSlice";
import useNotifications from "@hooks/useNotifications";
import { Box } from "@mui/material";
import about from "@static/icons/about.svg";
import home from "@static/icons/home.svg";
import logoutSVG from "@static/icons/logout.svg";
import messaging from "@static/icons/messaging.svg";
import notification from "@static/icons/notification.svg";
import { ENABLE_MESSAGING_FEATURE } from "src/config";
import { AppDispatch } from "src/services/store";

interface NavigationButtonsProps {
  isLoggedIn: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  isLoggedIn
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [, notificationsActions] = useNotifications();

  const handleLoginLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (isLoggedIn) {
      const logoutResponse = await dispatch(logout());
      if (logoutResponse.payload.logoutUrl) {
        return (window.location.href = logoutResponse.payload.logoutUrl);
      }
      navigate("/");
    } else {
      navigate("/get-started");
    }
  };

  const handleMessagingButtonClick = () => {
    navigate("/dashboard/messaging");
  };

  return (
    <Box
      sx={{
        width: "auto",
        display: { xs: "none", md: "flex" },
        justifyContent: "space-between",
        alignItems: "center",
        gap: 3
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <TextButton
          size='medium'
          color='inherit'
          href={isLoggedIn ? "/dashboard/home" : "/"}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px"
          }}
        >
          <img src={home} alt='Home' className='w-5 h-5' />
          <BodySmall fontSize={12}>Home</BodySmall>
        </TextButton>
        <TextButton
          size='small'
          color='inherit'
          href='/about'
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px"
          }}
        >
          <img src={about} alt='About' className='w-5 h-5' />
          <BodySmall fontSize={12}>About</BodySmall>
        </TextButton>
        {isLoggedIn ? (
          <>
            {ENABLE_MESSAGING_FEATURE && (
              <TextButton
                size='small'
                color='inherit'
                href=''
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px"
                }}
                onClick={handleMessagingButtonClick}
              >
                <img src={messaging} alt='Messaging' className='w-5 h-5' />
                <BodySmall fontSize={12}>Messaging</BodySmall>
              </TextButton>
            )}
            <TextButton
              size='small'
              color='inherit'
              href=''
              onClick={
                (
                  notificationsActions as {
                    on: () => void;
                    off: () => void;
                    toggle: () => void;
                  }
                ).toggle
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px"
              }}
            >
              <img src={notification} alt='Notifications' className='w-5 h-5' />
              <BodySmall fontSize={12}>Notifications</BodySmall>
              <NotificationsCard />
            </TextButton>
          </>
        ) : null}
      </Box>
      <Box
        sx={{
          gap: "10px",
          display: { xs: "none", md: "flex" },
          alignItems: "center"
        }}
      >
        {isLoggedIn ? (
          <PrimaryButton
            aria-label={"log out"}
            endIcon={
              <img src={logoutSVG} alt='logout' className='w-[14px] h-[14px]' />
            }
            onClick={handleLoginLogout}
            sx={{ width: "max-content" }}
          >
            Logout
          </PrimaryButton>
        ) : (
          <SecondaryButton
            size='small'
            color='primary'
            aria-label={"log in"}
            onClick={handleLoginLogout}
            sx={{ width: "max-content" }}
          >
            Login
          </SecondaryButton>
        )}

        {!isLoggedIn && (
          <PrimaryButton
            size='small'
            aria-label='register button'
            href='/get-started'
            sx={{ width: "max-content" }}
          >
            Sign Up
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
};

export default NavigationButtons;
