import React, { FC, ReactNode } from "react";

import { H3, Subtitle1 } from "@components/base/Typography";
import User from "@interfaces/user";
import { Box } from "@mui/material";

interface DashboardSidebarDesktopProps {
  children: ReactNode;
  //eslint-disable-next-line
  loading: any;
  currentUser?: User;
  hideInstitution?: boolean;
}

const DashboardSidebarDesktop: FC<DashboardSidebarDesktopProps> = ({
  children,
  currentUser,
  loading,
  hideInstitution
}) => {
  const institutionName = currentUser?.institutionName || "";

  return (
    <div className=' md:flex md:shrink-0 pt-6 bg-white border relative'>
      <div className={`flex flex-col ${hideInstitution ? "w-full" : "w-72"}`}>
        <div className='flex flex-col flex-grow pt-6 pb-3 h-screen overflow-y-auto'>
          {!hideInstitution && (
            <>
              <Box
                component='div'
                sx={{
                  display: {
                    xs: "none",
                    sm: "block"
                  },
                  textAlign: "center",
                  width: "100%",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                <H3
                  fontFamily={'"Shippori-Mincho-B1", serif'}
                  textTransform={"uppercase"}
                  fontSize={24}
                  fontWeight={600}
                  textAlign={"center"}
                >
                  {loading?.main ? "Loading... University" : institutionName}
                </H3>
              </Box>

              <Box mb={5} mt={2} px={1}>
                <Subtitle1
                  fontFamily={'"Shippori-Mincho-B1", serif'}
                  textTransform={"uppercase"}
                  fontSize={14}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  RESOURCE SHARING PORTAL
                </Subtitle1>
              </Box>
            </>
          )}
          <div className='flex-grow flex flex-col'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebarDesktop;
