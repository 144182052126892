import React from "react";

import { ButtonProps, Button as MuiButton, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * @module Button
 * @description A collection of customized button components built on top of MUI Button
 */

/**
 * @interface BaseButtonProps
 * @extends {ButtonProps}
 * @description Base props interface for all button components
 *
 * @property {React.ReactNode} children - The content of the button
 * @property {boolean} [loading] - Whether the button is in a loading state
 * @property {'small' | 'medium' | 'large'} [size='medium'] - The size of the button
 * @property {React.ReactNode} [startIcon] - Icon element placed before the children
 * @property {React.ReactNode} [endIcon] - Icon element placed after the children
 */

interface BaseButtonProps extends ButtonProps {
  children: React.ReactNode;
  loading?: boolean;
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  component?: React.ElementType;
}

// Size variants mapping
const sizeStyles = {
  small: {
    padding: "6px 12px",
    fontSize: "0.875rem"
  },
  medium: {
    padding: "8px 16px",
    fontSize: "1rem"
  },
  large: {
    padding: "10px 20px",
    fontSize: "1.125rem"
  }
};

// Base styled button with common styles
const BaseButton = styled(MuiButton)(() => ({
  boxShadow: "none",
  textTransform: "none",
  minWidth: "80px",
  borderRadius: "50px", // Standardized from theme
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    boxShadow: "none"
  },
  "&:active": {
    boxShadow: "none"
  },
  "&:focus": {
    boxShadow: "none"
  }
}));

// Loading wrapper component
const LoadingWrapper = ({
  loading,
  children
}: {
  loading?: boolean;
  children: React.ReactNode;
}) => {
  if (!loading) return <>{children}</>;
  return (
    <>
      <CircularProgress
        size={20}
        sx={{
          position: "absolute",
          left: "50%",
          marginLeft: "-10px"
        }}
      />
      <span className='invisible'>{children}</span>
    </>
  );
};

/**
 * @component PrimaryButton
 * @description Primary action button with contained styling
 *
 * @example
 * <PrimaryButton
 *   loading={isLoading}
 *   size="large"
 *   startIcon={<SaveIcon />}
 *   onClick={handleSave}
 * >
 *   Save Changes
 * </PrimaryButton>
 */

// Primary Button
export const PrimaryButton = React.forwardRef<
  HTMLButtonElement,
  BaseButtonProps
>(
  (
    { children, size = "medium", loading, startIcon, endIcon, ...props },
    ref
  ) => (
    <BaseButton
      ref={ref}
      variant='contained'
      color='primary'
      size={size}
      disabled={loading}
      startIcon={!loading && startIcon}
      endIcon={!loading && endIcon}
      sx={{ position: "relative", ...sizeStyles[size] }}
      {...props}
    >
      <LoadingWrapper loading={loading}>{children}</LoadingWrapper>
    </BaseButton>
  )
);
PrimaryButton.displayName = "PrimaryButton";

/**
 * @component SecondaryButton
 * @description Secondary action button with outlined styling
 *
 * @example
 * <SecondaryButton onClick={handleCancel}>
 *   Cancel
 * </SecondaryButton>
 */

// Secondary Button
export const SecondaryButton = React.forwardRef<
  HTMLButtonElement,
  BaseButtonProps
>(({ children, disabled, loading, ...props }, ref) => (
  <BaseButton
    ref={ref}
    variant='outlined'
    disabled={disabled || loading}
    {...props}
  >
    {children}
  </BaseButton>
));
SecondaryButton.displayName = "SecondaryButton";

/**
 * @component ProfileButton
 * @description Alternative action button with outlined styling
 *
 * @example
 * <ProfileButton onClick={handleCancel}>
 *   Share
 * </ProfileButton>
 */

// Profile Button
export const ProfileButton = React.forwardRef<
  HTMLButtonElement,
  BaseButtonProps
>(({ children, disabled, size = "medium", loading, ...props }, ref) => (
  <BaseButton
    ref={ref}
    size={size}
    variant='outlined'
    disabled={disabled || loading}
    sx={{ position: "relative", ...sizeStyles[size] }}
    {...props}
  >
    {children}
  </BaseButton>
));
ProfileButton.displayName = "ProfileButton";

/**
 * @component TextButton
 * @description Text-only button for subtle actions
 *
 * @example
 * <TextButton onClick={handleLearnMore}>
 *   Learn More
 * </TextButton>
 */

// Text Button
export const TextButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ children, disabled, loading, ...props }, ref) => (
    <BaseButton
      ref={ref}
      variant='text'
      color='primary'
      disabled={disabled || loading}
      {...props}
    >
      {children}
    </BaseButton>
  )
);
TextButton.displayName = "TextButton";

/**
 * @component GhostButton
 * @description Ghost button with custom hover states
 * Uses theme.palette.text.secondary for color and theme.palette.surface.dark for hover
 *
 * @example
 * <GhostButton onClick={handleSkip}>
 *   Skip for now
 * </GhostButton>
 */

// Ghost Button
export const GhostButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ children, disabled, loading, ...props }, ref) => (
    <BaseButton
      ref={ref}
      variant='text'
      sx={{
        color: "text.secondary",
        "&:hover": {
          backgroundColor: "surface.dark",
          color: "text.primary"
        }
      }}
      disabled={disabled || loading}
      {...props}
    >
      {children}
    </BaseButton>
  )
);
GhostButton.displayName = "GhostButton";

/**
 * @component RequestButton
 * @description Specialized button for request actions
 * Supports both contained and outlined variants defined in theme
 *
 * @example
 * <RequestButton
 *   variant="contained"
 *   loading={isRequesting}
 *   startIcon={<RequestIcon />}
 * >
 *   Request Access
 * </RequestButton>
 */

// Request Button
export const RequestButton = React.forwardRef<
  HTMLButtonElement,
  BaseButtonProps
>(
  (
    {
      children,
      variant = "contained",
      size = "medium",
      loading,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => (
    <BaseButton
      ref={ref}
      variant={variant === "contained" ? "requestContained" : "requestOutlined"}
      size={size}
      disabled={loading}
      startIcon={!loading && startIcon}
      endIcon={!loading && endIcon}
      sx={{ position: "relative", ...sizeStyles[size] }}
      {...props}
    >
      <LoadingWrapper loading={loading}>{children}</LoadingWrapper>
    </BaseButton>
  )
);
RequestButton.displayName = "RequestButton";

/**
 * @component ShareButton
 * @description Specialized button for share actions
 * Supports both contained and outlined variants defined in theme
 *
 * @example
 * <ShareButton
 *   variant="outlined"
 *   startIcon={<ShareIcon />}
 * >
 *   Share Resource
 * </ShareButton>
 */

// Share Button
export const ShareButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      children,
      variant = "contained",
      size = "medium",
      loading,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => (
    <BaseButton
      ref={ref}
      variant={variant === "contained" ? "shareContained" : "shareOutlined"}
      size={size}
      disabled={loading}
      startIcon={!loading && startIcon}
      endIcon={!loading && endIcon}
      sx={{ position: "relative", ...sizeStyles[size] }}
      {...props}
    >
      <LoadingWrapper loading={loading}>{children}</LoadingWrapper>
    </BaseButton>
  )
);
ShareButton.displayName = "ShareButton";
