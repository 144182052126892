import React from "react";

import { Input } from "@components/base/Input";
import { BodyLarge } from "@components/base/Typography";
import { renderStrainName } from "@features/utils/render-strain-name";
import { Autocomplete, CircularProgress } from "@mui/material";
import { autoCompleteStyles } from "src/styles/main";

interface ResourceAutocompleteProps {
  id?: string;
  resourceNames: string[];
  defaultValue?: string[];
  loading?: boolean;
  label: string;
  optionsFilter: (options: string[], state: { inputValue: string }) => string[];
  onChange: (event: React.ChangeEvent<unknown>, newValue: string[]) => void;
  multiple?: boolean;
  error?: boolean;
  helperText: string | "";
  disabled?: boolean;
  fullWidth?: boolean;
  requiredField?: boolean;
  // onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autocompleteInputProps?: any;
  isInstitutionField?: boolean;
  excludeAddOption?: boolean;
}

export const ResourceAutocomplete: React.FC<ResourceAutocompleteProps> = ({
  id,
  resourceNames,
  defaultValue,
  loading,
  optionsFilter,
  onChange,
  label,
  multiple,
  error,
  helperText,
  disabled,
  fullWidth,
  requiredField,
  onBlur,
  autocompleteInputProps,
  isInstitutionField,
  excludeAddOption,
  ...rest
}) => {
  return (
    <Autocomplete
      id={`${id}-autocomplete`}
      {...(multiple
        ? {
            multiple,
            defaultValue
          }
        : { defaultValue })}
      freeSolo
      fullWidth={fullWidth}
      autoHighlight
      selectOnFocus
      onBlur={onBlur}
      clearOnBlur
      handleHomeEndKeys
      loading={loading}
      disabled={disabled}
      ChipProps={{
        color: "primary",
        variant: "outlined"
      }}
      className='flex-grow'
      onChange={onChange}
      filterOptions={optionsFilter}
      getOptionLabel={(option) => option || ""}
      options={resourceNames}
      renderInput={(params) => (
        <Input
          {...params}
          label={
            requiredField ? (
              <BodyLarge>
                {label}
                <sup className='text-red-500 font-bold'>*</sup>
              </BodyLarge>
            ) : (
              label
            )
          }
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          inputProps={
            autocompleteInputProps
              ? { ...params.inputProps, ...autocompleteInputProps }
              : params.inputProps
          }
        />
      )}
      renderOption={(props, option) => {
        if (!option) return null; // No empty options

        const isExistingOption = resourceNames.includes(option);

        if (!isExistingOption && excludeAddOption) {
          // Don't render the option if it's not in resourceNames and excludeAddOption is true
          return null;
        }

        return (
          <li {...props} className={autoCompleteStyles}>
            {isExistingOption ? (
              isInstitutionField ? (
                option
              ) : (
                renderStrainName(option)
              )
            ) : isInstitutionField ? (
              <>Add &quot;{option}&quot;</>
            ) : (
              <>Add &quot;{renderStrainName(option)}&quot;</>
            )}
          </li>
        );
      }}
      {...rest}
    />
  );
};
