/* eslint-disable @typescript-eslint/no-explicit-any */
import getError from "@features/utils/get-error";
import { ResourceProperties } from "@interfaces/resource-values";
import {
  initialDonateValues,
  initialStartResourceFormTransferValues
} from "@interfaces/transfer";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

interface TransferState {
  transfer: {
    resource_data: {
      category?: string;
      source?: string;
      resourcePropsValues?: ResourceProperties;
      strainName?: string;
      mutation_type_names?: string[];
      target_gene_names?: string[];
      species_name?: string;
      [key: string]: any;
    };
  };
  donation: Record<string, any>;

  error: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: TransferState = {
  transfer: { ...initialStartResourceFormTransferValues, resource_data: {} },
  donation: initialDonateValues,
  status: "idle",
  error: null
};

export const createTransfer = createAsyncThunk(
  "transfer/createTransfer",
  async (
    normalizedValues: Record<string, any>,
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.post("/transfer", normalizedValues, {
        cancelToken: source.token
      });

      const userName = normalizedValues.donor_full_name;
      const otherUserName = normalizedValues.receiver_full_name;

      const transferMessage =
        userName?.length > 0 && otherUserName?.length > 0
          ? `Transfer of ${normalizedValues.strain} from ${userName} to ${otherUserName}`
          : `Transfer of ${normalizedValues.strain}`;

      enqueueSnackbar(transferMessage, { variant: "success" });
      return response.data;
    } catch (error) {
      // enqueueSnackbar(
      //   `Transfer of ${normalizedValues.strain} failed, please try again or contact info@labshareapp.com.`,
      //   { variant: "warning" }
      // );
      return rejectWithValue(getError(error));
    }
  }
);

export const createDonationTransfer = createAsyncThunk(
  "transfer/createDonationTransfer",
  async (
    normalizedValues: Record<string, any>,
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.post("/transfer", normalizedValues, {
        cancelToken: source.token
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    updateResourceData: (state, action: PayloadAction<any>) => {
      state.transfer.resource_data = {
        ...state.transfer.resource_data,
        ...action.payload
      };
    },
    resetTransfer: (state) => {
      state.transfer = initialState.transfer;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTransfer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transfer = action.payload;
      })
      .addCase(createTransfer.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createDonationTransfer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDonationTransfer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.donation = action.payload;
      })
      .addCase(createDonationTransfer.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const { name, actions } = transferSlice;

const getSlice = (state: RootState) => state[name];

export const selectTransfer = createSelector(
  getSlice,
  (slice) => slice?.transfer || initialState
);

export const selectDonation = createSelector(
  getSlice,
  (slice) => slice?.donation || initialDonateValues
);

export const selectTransferStatus = createSelector(
  getSlice,
  (slice) => slice.status
);
export const selectResourceData = createSelector(
  selectTransfer,
  (transfer) => transfer?.resource_data || {}
);
export const { updateResourceData, resetTransfer } = actions;
