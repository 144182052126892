import React from "react";

import { SecondaryButton } from "@components/base/Button";
import { BodyLarge, Subtitle1 } from "@components/base/Typography";
import PrivacyPolicyDialog from "@components/Dialogs/PrivacyPolicyDialog";
import TermsOfServiceDialog from "@components/Dialogs/TermsOfServiceDialog";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import briefcase from "@static/icons/briefcase.svg";
import marker from "@static/icons/marker.svg";
import phone from "@static/icons/phone.svg";
import Logo from "@static/labsharelogo_white.svg";

import SocialLinks from "./SocialLinks";

const Footer = () => {
  // set up terms of service modal
  const [openTermsOfService, setOpenTermsOfService] = React.useState(false);
  const handleOpenTermsOfService = () => {
    setOpenTermsOfService(true);
  };
  const handleCloseTermsOfService = () => {
    setOpenTermsOfService(false);
  };

  //set up privacy policy modal
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const handleOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const year = new Date().getFullYear();
  return (
    <Box
      component='footer'
      sx={{
        background: (theme) => theme.palette.primary.main,
        width: "100%",
        color: "neutral.white",
        px: { md: 10, xs: 5 },
        py: 3,
        position: "relative",
        zIndex: 0
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(4, 1fr)"
          },
          gap: 10,
          py: 5
        }}
      >
        <Stack spacing={3}>
          <Subtitle1 fontWeight='bold'>
            <Box
              component='img'
              sx={{
                objectFit: "contain",
                height: { xs: 30, lg: 40 },
                ml: -1.2
              }}
              alt='LabShare logo'
              src={Logo}
            />
          </Subtitle1>
          <BodyLarge>
            A collaborative platform for researchers to share and discover
            animal models, biospecimens, and more.
          </BodyLarge>
        </Stack>
        <Stack spacing={1}>
          <Subtitle1 fontWeight='bold'>LEGAL &amp; PRIVACY</Subtitle1>
          <Link
            color='neutral.white'
            underline='hover'
            sx={{ cursor: "default" }}
            onClick={handleOpenPrivacyPolicy}
          >
            Privacy Policy
          </Link>
          <Link
            color='neutral.white'
            underline='hover'
            sx={{ cursor: "default" }}
            onClick={handleOpenTermsOfService}
          >
            Terms of Service
          </Link>
        </Stack>
        <Stack spacing={3}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Subtitle1 fontWeight='bold'>QUICK LINKS</Subtitle1>
            <Link href='/about' color='neutral.white' underline='hover'>
              About Us
            </Link>
            <Link href='/get-started' color='neutral.white' underline='hover'>
              Join LabShare
            </Link>
            <Link href='/faqs' color='neutral.white' underline='hover'>
              FAQs
            </Link>
          </Box>
          <Link
            href='https://forms.gle/Lz1R9RTdys8upTjF8'
            target='_blank'
            rel='noopener noreferrer'
          >
            <SecondaryButton
              variant='outlined'
              sx={{
                color: "neutral.white",
                borderColor: "border.white",
                width: "fit-content",
                mt: 0.5,
                py: 0,
                "&:hover": {
                  borderColor: "border.white"
                }
              }}
            >
              Submit Feedback
            </SecondaryButton>
          </Link>
        </Stack>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Subtitle1 fontWeight='bold'>GET IN TOUCH</Subtitle1>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box
                component='img'
                sx={{
                  objectFit: "contain",
                  height: 15
                }}
                src={marker}
              />
              <BodyLarge>New York, NY</BodyLarge>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box
                component='img'
                sx={{
                  objectFit: "contain",
                  height: 15
                }}
                src={briefcase}
              />
              <BodyLarge>
                <Link
                  href='mailto:info@labshareapp.com'
                  color='neutral.white'
                  underline='hover'
                >
                  info@labshareapp.com
                </Link>
              </BodyLarge>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box
                component='img'
                sx={{
                  objectFit: "contain",
                  height: 15
                }}
                src={phone}
              />
              <BodyLarge>+1 (732) 860-8696</BodyLarge>
            </Stack>
          </Stack>
          <SocialLinks />
        </Stack>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          py: 2
        }}
      >
        <BodyLarge sx={{ whiteSpace: "nowrap" }} color={"neutral.white"}>
          Copyright {year} All rights reserved
        </BodyLarge>
        <Box
          sx={{
            height: 1.1,
            flex: 1,
            display: { xs: "none", sm: "block" },
            backgroundColor: "background.paper",
            ml: 1
          }}
        />
      </Box>
      <PrivacyPolicyDialog
        openPrivacyPolicy={openPrivacyPolicy}
        handleClosePrivacyPolicy={handleClosePrivacyPolicy}
      />
      <TermsOfServiceDialog
        openTermsOfService={openTermsOfService}
        handleCloseTermsOfService={handleCloseTermsOfService}
      />
    </Box>
  );
};

export default Footer;
