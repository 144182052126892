import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { PrimaryButton } from "@components/base/Button";
import { Card } from "@components/base/Card";
import { BodySmall, H3 } from "@components/base/Typography";
import { requestAccessToInstitutionFeatures } from "@features/Lab/labslice";
import { Box, Link } from "@mui/material";
import backgroundImg from "@static/instDatabase.png";
import { AppDispatch } from "src/services/store";

import ContactUs from "./Landing/components/ContactUs";

interface NonPartnerInstitutionPageProps {
  showSidebar: boolean;
}

export default function NonPartnerInstitutionPage({
  showSidebar
}: NonPartnerInstitutionPageProps) {
  const dispatch: AppDispatch = useDispatch();
  const [showContactForm, setShowContactForm] = useState(false);

  const handleRequestAccess = () => {
    dispatch(requestAccessToInstitutionFeatures());
  };

  const handleContactUsClick = () => {
    setShowContactForm(true);
  };
  return (
    <>
      {showContactForm ? (
        <ContactUs />
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          <img
            src={backgroundImg}
            alt='Background'
            className='w-4/5 h-full object-cover filter blur-[8px]'
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: showSidebar ? 0 : { xs: "42%", md: "50%" },
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              transform: showSidebar
                ? "translateX(-150px)"
                : "translateX(-50%)",
              transition: "left 0.5s ease, transform 0.5s ease"
            }}
          >
            <Card
              sx={{
                maxWidth: { xs: 250, sm: 400 }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 3
                }}
              >
                <H3 align='center' sx={{ fontWeight: "bold" }}>
                  Your institution is not registered on LabShare
                </H3>
                <PrimaryButton
                  size='large'
                  fullWidth
                  onClick={handleRequestAccess}
                  sx={{
                    mt: 1,
                    py: 1.5,
                    maxWidth: 300
                  }}
                >
                  Request Access
                </PrimaryButton>
                <BodySmall align='left'>
                  We&apos;ll reach out to your admin to activate your
                  institutional portal. If you&apos;re a research admin, please{" "}
                  <Link
                    href='#contact'
                    color='primary'
                    underline='hover'
                    onClick={handleContactUsClick}
                  >
                    contact us
                  </Link>
                  .
                </BodySmall>
              </Box>
            </Card>
          </Box>
        </Box>
      )}
    </>
  );
}
