import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryButton } from "@components/base/Button";
import { Input } from "@components/base/Input";
import { H2, H4 } from "@components/base/Typography";
import { getUser } from "@features/Auth/authSlice";
import {
  resetContactState,
  submitContactForm
} from "@features/Contact/contactSlice";
import User from "@interfaces/user";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import { AppDispatch } from "src/services/store";
import { theme } from "src/styles/theme";
import * as Yup from "yup";

// Validation Schema
const validationSchema = Yup.object({
  fullname: Yup.string().required("Full name is required"),
  institutionName: Yup.string().required("Institution name is required"),
  institutionEmail: Yup.string()
    .email("Invalid email address")
    .required("Institution email is required"),
  jobTitle: Yup.string(),
  message: Yup.string().required("Message is required")
});

export default function ContactUs() {
  const dispatch: AppDispatch = useDispatch();
  const currentUser: User = useSelector(getUser);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      fullname: "",
      institutionName: "",
      institutionEmail: currentUser?.email || "",
      jobTitle: "",
      message: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // send the data for email to be sent
      dispatch(submitContactForm(values))
        .unwrap()
        .then(() => {
          formik.resetForm();
          dispatch(resetContactState());
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  return (
    <Box
      id='contact-us'
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        margin: "auto",
        textAlign: "center",
        paddingY: "2rem",
        paddingX: { xs: "1rem", sm: "2rem", lg: "4rem" }
      }}
    >
      <Stack spacing={5} sx={{ maxWidth: { sx: "100%", md: "70%" } }}>
        <Stack>
          <H2
            align='center'
            sx={{
              fontSize: { xs: "1.5rem", sm: "2.5rem", lg: "3rem" },
              fontWeight: "600"
            }}
          >
            Let&apos;s work together
          </H2>
          <br />
          <H4
            align='center'
            sx={{ fontSize: { xs: "1rem", sm: "1.4rem", lg: "1.8rem" } }}
          >
            Whether you&apos;re a researcher or an institutional admin, we look
            forward to working with you to accelerate research. Leave your
            details and we&apos;ll setup a meeting with you
          </H4>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              {/* First Line - Full Name, Institution Name */}
              <Grid item xs={12} sm={6}>
                <Input
                  fullWidth
                  label='Full Name'
                  name='fullname'
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.fullname && Boolean(formik.errors.fullname)
                  }
                  helperText={
                    formik.touched.fullname ? formik.errors.fullname || "" : ""
                  }
                  required
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color: theme.palette.neutral.red
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  fullWidth
                  label='Institution Name'
                  name='institutionName'
                  value={formik.values.institutionName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.institutionName &&
                    Boolean(formik.errors.institutionName)
                  }
                  helperText={
                    formik.touched.institutionName
                      ? formik.errors.institutionName || " "
                      : ""
                  }
                  required
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color: theme.palette.neutral.red
                    }
                  }}
                />
              </Grid>

              {/* Second Line - Institution Email, Job Title */}
              <Grid item xs={12} sm={6}>
                <Input
                  fullWidth
                  label='Institution Email'
                  type='email'
                  name='institutionEmail'
                  value={formik.values.institutionEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.institutionEmail &&
                    Boolean(formik.errors.institutionEmail)
                  }
                  helperText={
                    formik.touched.institutionEmail
                      ? formik.errors.institutionEmail || ""
                      : ""
                  }
                  required
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color: theme.palette.neutral.red
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  fullWidth
                  label='Job Title'
                  name='jobTitle'
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              {/* Third Line - Message */}
              <Grid item xs={12}>
                <Input
                  fullWidth
                  label='Message'
                  name='message'
                  multiline
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={
                    formik.touched.message ? formik.errors.message || "" : ""
                  }
                  required
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color: theme.palette.neutral.red
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Box textAlign={"center"}>
              {/* Submit Button */}
              <PrimaryButton
                type='submit'
                size='large'
                disabled={formik.isSubmitting}
                sx={{
                  width: { xs: "80%", md: "50%", lg: "30%" }
                }}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  "Submit"
                )}
              </PrimaryButton>
            </Box>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}
