import React from "react";

import { BodySmall } from "@components/base/Typography";
import { constants } from "@features/utils/constants";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";

interface CategorySelectorProps {
  resourceCategory: string;
  handleCategoryChange: (event: SelectChangeEvent<unknown>) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  resourceCategory,
  handleCategoryChange
}) => {
  return (
    <FormControl fullWidth margin='normal'>
      <InputLabel id='category-label'>
        <BodySmall>
          Category
          <sup className='text-red-500 font-bold'>*</sup>
        </BodySmall>
      </InputLabel>
      <Select
        labelId='category-label'
        id='category'
        name='category'
        value={resourceCategory}
        onChange={handleCategoryChange}
        label='Category'
        required
        fullWidth
      >
        <MenuItem value={constants.animalModel}>
          Animal model (live animal, cryo-preserved)
        </MenuItem>
        <MenuItem value={constants.cellLine}>
          Cell Culture (cells, cell lines)
        </MenuItem>
        <MenuItem value={constants.biospecimen}>Biospecimen (tissues)</MenuItem>
        <MenuItem value={constants.plasmid}>Plasmid</MenuItem>
        <MenuItem value={constants.antibody}>Antibody</MenuItem>
        <MenuItem value={constants.equipment}>Equipment</MenuItem>
        <MenuItem value={constants.other}>Other</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CategorySelector;
